import axios from 'axios';
import api from '../config/api'
import globalHelper from "../helper/globalHelper";

export const ActionTypes = {
  PARTNER_LOADED: "PARTNER_LOADED",
  SAVE_PARTNER: "SAVE_PARTNER",
  PARTNER_DELETE: "PARTNER_DELETE",
  UPDATE_PARTNER: "UPDATE_PARTNER",
};

//list, Create, edit, update, delete

/**
 * List all partners
 */
export const getPartner = () => async (dispatch) => {
  axios
    .get(api.partner)
    // .then(res => console.log("result -->",res.data))
    .then((result) => {
      dispatch({
        type: ActionTypes.PARTNER_LOADED,
        partners: result.data.data,
      });
    });
};

/**
 * Create partners
 */
//  export const savePartner = (values) => async dispatch => {
//     const retResponse = { status: false, data: {} };
//     try {
//         const { data } = await axios.post(api.createPartner, values);
//         if (data.success) {
//             dispatch({
//                 type: ActionTypes.SAVE_PARTNER,
//                 payload: data.data
//             });
//             globalHelper.message("New Partner created successfully", "success");
//         }
//     } catch (e) {
//         if(e.response && e.response.data){
//             retResponse.data = (e.response && e.response.data) ? e.response.data : retResponse.data;
//             globalHelper.message(e.response.data.message, "error");
//         }
//     }
//     return retResponse;
// }
export const savePartner = values => async dispatch => {
  const retResponse = { status: false, data: {} };
  try {
    const { data } = await axios.post(api.createPartner, values);
    if (data.success) {
      dispatch({
        type: ActionTypes.SAVE_PARTNER,
        payload: data.data,
      });
      globalHelper.message("Partner created successfully", "success");
    }
  } catch (e) {
    if (e.response && e.response.data) {
      retResponse.data = (e.response && e.response.data) ? e.response.data : retResponse.data;
      globalHelper.message(e.response.data.message, "error");
    }
  }
  return retResponse;
}
/**
 * Edit partners
 */
export const editPartner = (values, Id) => async dispatch => {
  const retResponse = { status: false, data: {} };
  try {
    const { data } = await axios.put(`${api.partner}/${Id}`, values);
    // const { data } = await axios.put(`${api.partner}/${Id}`, values);
    if (data.success) {
      dispatch({
        type: ActionTypes.UPDATE_PARTNER,
        payload: data.data
      });
      globalHelper.message("Partner updated successfully", "success");
    }
  } catch (e) {
    if (e.response && e.response.data) {
      retResponse.data = (e.response && e.response.data) ? e.response.data : retResponse.data;
      globalHelper.message(e.response.data.message, "error");
    }
  }
  return retResponse;
}

/**
 * Delete Partner
 */
export const deletePartner = (Id) => async dispatch => {
  axios.delete(`${api.partner}/${Id}`)
    .then(result => {
      dispatch({
        type: ActionTypes.PARTNER_DELETE,
        payload: { Id }
      })
      globalHelper.message("Partner deleted successfully", "success");
    })
}