import { FORGOT_PASSWORD } from '../actions/authActions'

export function forgotPassReducer(state = [], action) {
  switch (action.type) {

    case FORGOT_PASSWORD:
      return action.forgotPass

    default:
      return state
  }
}