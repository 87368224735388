import React, { Component } from "react";
import { connect } from "react-redux";
import { GetLocalStorage } from "../../services/SMILocalStorage";
import { logoutAction } from "../../actions/authActions";

class HeaderLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
    };
  }

  myHandler = (e) => {
    e.preventDefault();
    this.props
      .logout()
      .then((user) => this.props.history.push("/login"))
      .catch((err) => console.log("Failure", err));
  };

  userDetails = GetLocalStorage("smi-user");

  render() {
    return (
      <header
        className="m-grid__item    m-header "
        data-minimize-mobile="hide"
        data-minimize-offset="200"
        data-minimize-mobile-offset="200"
        data-minimize="minimize"
      >
        <div className="m-container m-container--fluid m-container--full-height">
          <div className="m-stack m-stack--ver m-stack--desktop">
            <div className="m-stack__item m-brand  m-brand--skin-dark ">
              <div className="m-stack m-stack--ver m-stack--general">
                <div className="m-stack__item m-stack__item--middle m-brand__logo">
                  <a href="dashboard" className="m-brand__logo-wrapper">
                    <img alt="" src="/images/logo-dashboard.png" />
                  </a>
                </div>
                <div className="m-stack__item m-stack__item--middle m-brand__tools">
                  <div
                    id="m_aside_left_minimize_toggle"
                    className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block"
                  >
                    <span></span>
                  </div>
                  <div
                    id="m_aside_left_offcanvas_toggle"
                    className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block"
                  >
                    <span></span>
                  </div>
                  <div
                    id="m_aside_header_menu_mobile_toggle"
                    className="m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block"
                  >
                    <span></span>
                  </div>
                  <div
                    id="m_aside_header_topbar_mobile_toggle"
                    className="m-brand__icon m--visible-tablet-and-mobile-inline-block"
                  >
                    <i className="flaticon-more"></i>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="m-stack__item m-stack__item--fluid m-header-head"
              id="m_header_nav"
            >
              <div
                id="m_header_topbar"
                className="m-topbar  m-stack m-stack--ver m-stack--general"
              >
                <div className="m-stack__item m-topbar__nav-wrapper">
                  <ul className="m-topbar__nav m-nav m-nav--inline">
                    <li
                      className="m-nav__item m-topbar__user-profile m-topbar__user-profile--img  m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
                      data-dropdown-toggle="click"
                    >
                      <a href="#x" className="m-nav__link m-dropdown__toggle">
                        <span className="m-topbar__userpic">
                          <img
                            src="/images/avtar.png"
                            className="m--img-rounded m--marginless m--img-centered"
                            alt=""
                          />
                        </span>
                        <span className="m-topbar__username m--hide">Nick</span>
                      </a>
                      <div className="m-dropdown__wrapper">
                        <span className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                        <div className="m-dropdown__inner">
                          <div className="m-dropdown__header m--align-center">
                            <div className="m-card-user m-card-user--skin-dark">
                              <div className="m-card-user__pic">
                                <img
                                  src="/images/avtar.png"
                                  className="m--img-rounded m--marginless"
                                  alt=""
                                />
                              </div>
                              <div className="m-card-user__details">
                                <span className="m-card-user__name m--font-weight-500">
                                  {this.userDetails.user.name}
                                </span>
                                <a
                                  href="#x"
                                  className="m-card-user__email m--font-weight-300 m-link"
                                >
                                  {this.userDetails.user.email}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="m-dropdown__body">
                            <div className="m-dropdown__content">
                              <ul className="m-nav m-nav--skin-light">
                                <li className="m-nav__section m--hide">
                                  <span className="m-nav__section-text">
                                    Section
                                  </span>
                                </li>
                                {/* <li className="m-nav__item">
                                  <a href="/#" className="m-nav__link">
                                    <i className="m-nav__link-icon flaticon-profile-1"></i>
                                    <span className="m-nav__link-title">
                                      <span className="m-nav__link-wrap">
                                        <span className="m-nav__link-text">
                                          My Profile
                                        </span>
                                        <span className="m-nav__link-badge">
                                           <span className="m-badge m-badge--success">
                                          2
                                        </span>
                                        </span>
                                      </span>
                                    </span>
                                  </a>
                                </li> */}
                                <li className="m-nav__separator m-nav__separator--fit"></li>
                                <li className="m-nav__item">
                                  <a
                                    href="#x"
                                    onClick={this.myHandler}
                                    className="btn m-btn--pill    btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder"
                                  >
                                    Logout
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default connect(null, (dispatch) => ({
  dispatch,
  logout: () => dispatch(logoutAction()),
}))(HeaderLayout);
