import React, { Component } from "react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";

import {
  getDefaultDeviceTypeAction,
  getDefaultCapabilitiesAction,
  createDeviceTypeAction
} from "../../../actions/deviceTypeActions";

class DeviceTypeAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        name: "",
        userId: "",
        firmwareId: "",
        deviceType: "",
        deviceCapability: []
      },
      errors: {}
    };

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  componentDidMount() {
    this.props.getDefaultDeviceType();
    this.props.getDefaultCapabilities();
  }

  getInitialState = () => {
    return {
      data: {
        name: "",
        userId: "",
        firmwareId: "",
        deviceType: "",
        deviceCapability: []
      },
      errors: {}
    };
  };

  handleSubmit = e => {
    e.preventDefault();
    const { data } = this.state;
    if (this.validator.allValid()) {
      this.props
        .createType(data)
        .then(() => {
          this.props.history.push("/device-type");
          this.setState(this.getInitialState());
        })
        .catch(err => console.log("Failure", err));
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleChange = e => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value
      }
    });
  };

  handleCheckChange = e => {
    let deviceCapability = this.state.data.deviceCapability;
    if (typeof deviceCapability[e.target.value] === "undefined") {
      deviceCapability[e.target.value] = e.target.value;
    } else {
      delete deviceCapability[e.target.value];
    }
    this.setState({
      data: {
        ...this.state.data,
        deviceCapability: Object.assign({}, deviceCapability)
      }
    });
  };

  render() {
    const { defaultDeviceType } = this.props;
    const checkboxes = [
      {
        id: "5dcbd94faf0e7a24dd0dd53c",
        name: "deviceCapability[]",
        key: "checkBox1",
        value: "5dcbd94faf0e7a24dd0dd53c",
        label: "On/Off"
      },
      {
        id: "5dcbd972af0e7a24dd0dd55e",
        name: "deviceCapability[]",
        key: "checkBox2",
        value: "5dcbd972af0e7a24dd0dd55e",
        label: "Speed"
      }
    ];
    return (
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content">
          <div className="m-portlet m-portlet--mobile">
            <div className="m-portlet__head">
              <div className="m-portlet__head-caption">
                <div className="m-portlet__head-title">
                  <h3 className="m-portlet__head-text">Create Device Type</h3>
                </div>
              </div>
            </div>
            <form
              className="m-form m-form--fit m-form--label-align-right m-form--group-seperator"
              onSubmit={this.handleSubmit}
            >
              <div className="m-portlet__body">
                <div className="m-form__section m-form__section--first">
                  <div className="form-group m-form__group row">
                    <label className="col-lg-2 col-form-label">
                      Type Name:
                    </label>
                    <div className="col-lg-6">
                      <input
                        type="text"
                        name="name"
                        className="form-control m-input"
                        onChange={this.handleChange}
                        autoComplete="off"
                      />
                      {this.validator.message(
                        "name",
                        this.state.data.name,
                        "required"
                      )}
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <label className="col-lg-2 col-form-label">Kind:</label>
                    <div className="col-lg-6">
                      <select
                        className="form-control m-input"
                        name="deviceType"
                        onChange={this.handleChange}
                      >
                        {defaultDeviceType &&
                          defaultDeviceType.map((defaultType, index) => {
                            return (
                              <option key={index} value={defaultType._id}>
                                {defaultType.name}
                              </option>
                            );
                          })}
                      </select>
                      {this.validator.message(
                        "deviceType",
                        this.state.data.deviceType,
                        "required"
                      )}
                    </div>
                  </div>
                  <div className="m-form__group form-group row">
                    <label className="col-lg-2 col-form-label">
                      Capabilities:
                    </label>
                    <div className="m-checkbox-inline">
                      {checkboxes.map(item => (
                        <label className="m-checkbox" key={item.key}>
                          <input
                            type="checkbox"
                            name={item.name}
                            id={item.id}
                            value={item.value}
                            //checked="checked"
                            onChange={this.handleCheckChange}
                          />
                          {item.label}
                          <span></span>
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="m-portlet__foot m-portlet__foot--fit">
                <div className="m-form__actions m-form__actions">
                  <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-6">
                      <button type="submit" className="btn btn-primary">
                        Create Type
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    defaultDeviceType: state.defaultDeviceType,
    defaultCapatibility: state.defaultCapatibility
  }),
  dispatch => ({
    dispatch,
    createType: credentials => dispatch(createDeviceTypeAction(credentials)),
    getDefaultDeviceType: () => dispatch(getDefaultDeviceTypeAction()),
    getDefaultCapabilities: () => dispatch(getDefaultCapabilitiesAction())
  })
)(DeviceTypeAdd);
