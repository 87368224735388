import axios from 'axios';
import api from '../config/api'

export const ActionTypes = {
    CERTIFICATE_LOGS_LOADED: 'CERTIFICATE_LOGS_LOADED',
}

/**
 * Get Logs
 */
export const getLogs = () => async dispatch => {
    axios.get(api.certificateLogs)
        .then(result => {
            dispatch({
                type: ActionTypes.CERTIFICATE_LOGS_LOADED,
                certLogs: result.data.data
            })
        })
}