import {globalActionTypes as actionTypes } from "../actions/globalAction";

const initialState = {
  snackbar: {},
  module:""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLOSE_SNACKBAR:
      return {
        ...state,
        snackbar: {
          open: false,
          message: "",
          type: "success"
        }
      };
    case actionTypes.SHOW_SNACKBAR:
      return {
        ...state,
        snackbar: {
          open: action.payload.open,
          message: action.payload.message,
          type: action.payload.type
        }
      };
    default:
      return state;
  }
};
