import * as yup from "yup";

export const Validations = yup.object({
  name: yup.string().required(),
  logo: yup.string(),
  background_image: yup.string(),
  footer_image: yup.string(),
  //   auth_type: yup.number(),
  auth_message: yup.string().required(),
  auth_url: yup.string().when("auth_type", {
    is: (value) => value  ? value.value == 2 || value == 2 || value == 3 || value.value == 3 : "",
    then: yup.string().required(),
  }),
  login_url: yup.string().when("auth_type", {
    is: (value) => value  ? value.value == 2 || value == 2 || value == 3 || value.value == 3 || value == 4 || value.value == 4 || value == 5 || value.value == 5 || value == 6 || value.value == 6 : "",
    then: yup.string().required(),
  }),
  pre_shared_token: yup.string().when("auth_type", {
    is: (value) => value ? value.value == 4 || value.value == 5 || value.value == 6 || value == 6 || value == 5 || value == 4 : "",
    then: yup.string().required(),
  }),
  header_key_one: yup.string(),
  header_value_one: yup.string(),
  header_key_two: yup.string(),
  header_value_two: yup.string(),
  header_key_three: yup.string(),
  header_value_three: yup.string(),
  body_key_one: yup.string(),
  body_value_one: yup.string(),
  body_key_two: yup.string(),
  body_value_two: yup.string(),
  body_key_three: yup.string(),
  body_value_three: yup.string(),
});

export const defaultValue = {
  name: "",
  logo: "",
  background_image: "",
  footer_image: "",
  auth_type: "",
  auth_message: "",
  auth_url: "",
  login_url: "",
  pre_shared_token: "",
  default_partner: false,
  placeholder_Id: "",
  deviceTypes: [],
  header_key_one: "",
  header_value_one: "",
  header_key_two: "",
  header_value_two: "",
  header_key_three: "",
  header_value_three: "",
  body_key_one: "",
  body_value_one: "",
  body_key_two: "",
  body_value_two: "",
  body_key_three: "",
  body_value_three: "",
};
