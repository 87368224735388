import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { getDeviceAction } from "../../../actions/deviceActions";

//class PostList extends Component {

class DeviceList extends Component {
  /* constructor(props) {
    super(props);
  } */
  componentDidMount() {
    this.props.getDevices();
  }

  render() {
    const { devices } = this.props;

    const columns = [
      {
        label: "No.",
        name: "",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta) => {
            return tableMeta.rowIndex + 1;
          }
        }
      },
      {
        label: "UUID",
        name: "UUID",
        sortable: true,
        options: {
          filter: false
        }
      },
      {
        label: "Device Name",
        name: "name",
        sortable: true,
        options: {
          filter: false
        }
      },
      {
        label: "Status",
        name: "online",
        sortable: true,
        options: {
          customBodyRender: value => {
            return value ? "Online" : "Offline";
          }
        }
      },
      {
        label: "Device Type",
        name: "firmware_id",
        sortable: true,
        options: {
          customBodyRender: value => {
            const deviceType = value.split(":");
            return deviceType[deviceType.length - 1];
          }
        }
      },

      {
        label: "Created",
        name: "createdAt",
        sortable: true,
        options: {
          customBodyRender: value => {
            if (value !== undefined) {
              const localDate = moment
                .utc(value)
                .local()
                .format("DD-MM-YYYY hh:mm:s A");
              return localDate;
            }
            return "";
          }
        }
      }
    ];

    const data =
      devices && devices.devices && devices.devices.length
        ? devices.devices
        : [];
    const totalDevices =
      devices && devices.totalDevices ? devices.totalDevices : 0;
    const thisWeek = devices && devices.thisWeek ? devices.thisWeek : 0;
    const thisMonth = devices && devices.thisMonth ? devices.thisMonth : 0;

    const options = {
      selectableRows: "none",
      download: false,
      print: false,
      viewColumns: false,
      customToolbar: () => {
        return (
          <>
            <span className="m--font-bold">
              Total : {totalDevices} | Registered last 7 days : {thisWeek} |
              Registered last 30 days : {thisMonth}
            </span>
          </>
        );
      }
    };
    return (
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content">
          <div className="m-portlet m-portlet--mobile">
            <MUIDataTable
              title={"Devices"}
              data={data}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default connect(
  state => ({
    devices: state.devices
  }),
  dispatch => ({
    dispatch,
    getDevices: () => dispatch(getDeviceAction())
  })
)(DeviceList);
