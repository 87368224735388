import axios from 'axios'
import apiurl from '../config/api'

export function getDevice() {
  return axios.get(apiurl.getDevice)
    .then(result => {
      //const { access_token } = result.data.data;
      //axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
      return result.data.data
    })
}