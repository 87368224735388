import React, { Component } from "react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";

import { loginAction, forgotPassAction } from "../../actions/authActions";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        email: "",
        password: "",
        forgot_email: ""
      },
      loading: false,
      forgotPass: false,
      errors: {}
    };

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.forgotValidator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  getInitialState = () => {
    return {
      data: {
        email: "",
        password: "",
        forgot_email: ""
      },
      loading: false,
      forgotPass: false,
      errors: {}
    };
  };
  /**
   * On load render
   */

  handleSubmit = e => {
    e.preventDefault();
    const { data } = this.state;
    if (this.validator.allValid()) {
      this.setState({
        loading: true
      });
      this.props
        .login(data)
        .then(user => {
          if (user.error) {
            this.setState({
              data: this.state.data,
              loading: false,
              errors: user.error
            });
          } else {
            this.props.history.push("/devices");
          }
        })
        .catch(err => console.log("Failure", err));
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleForgotPassSubmit = e => {
    e.preventDefault();
    const { data } = this.state;
    if (this.forgotValidator.allValid()) {
      data.link = this.props.appUrl;
      this.setState({
        loading: true
      });
      this.props
        .forgotPass(data)
        .then(user => {
          if (user.error) {
            this.setState({
              data: this.state.data,
              loading: false,
              errors: user.error
            });
          } else {
            //this.props.history.push("/login");
          }
        })
        .catch(err => console.log("Failure", err));
    } else {
      this.forgotValidator.showMessages();
      this.forceUpdate();
    }
  };

  handleChange = e => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value
      },
      errors: {}
    });
  };

  isForgotPass = e => {
    e.preventDefault();
    this.setState({
      forgotPass: true
    });
  };

  isForgotPassCancel = e => {
    this.setState({
      forgotPass: false
    });
  };

  /**
   * Render page
   */
  render() {
    const { errors } = this.state;
    const loader = this.state.loading;
    const forPass = this.state.forgotPass;

    //if (!forPass) {
    return (
      <div className="m-grid m-grid--hor m-grid--root m-page">
        <div
          className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-grid--tablet-and-mobile m-grid--hor-tablet-and-mobile 		m-login m-login--1 m-login--singin"
          id="m_login"
        >
          <div className="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-login__aside">
            <div className="m-stack m-stack--hor m-stack--desktop">
              <div className="m-stack__item m-stack__item--fluid">
                <div className="m-login__wrapper">
                  <div className="m-login__logo">
                    <img alt="" src="/images/login-page-logo.png"></img>
                  </div>
                  <div className="m-login__signin">
                    <div className="m-login__head">
                      <h3 className="m-login__title">Sign In To Admin</h3>
                    </div>
                    {!forPass ? (
                      <form
                        className="m-login__form m-form m-login--forget-password"
                        onSubmit={this.handleSubmit}
                      >
                        <div className="form-group m-form__group">
                          <input
                            className="form-control m-input"
                            type="text"
                            placeholder="Email"
                            name="email"
                            autoComplete="off"
                            onChange={this.handleChange}
                            value={this.state.email}
                            ref={email => (this.input = email)}
                          />
                          {this.validator.message(
                            "email",
                            this.state.data.email,
                            "required|email"
                          )}
                          <div className="srv-validation-message">
                            {errors.password && errors.password.message
                              ? errors.password.message
                              : ""}
                          </div>
                        </div>
                        <div className="form-group m-form__group">
                          <input
                            className="form-control m-input m-login__form-input--last"
                            type="password"
                            placeholder="Password"
                            name="password"
                            onChange={this.handleChange}
                            value={this.state.password}
                          />
                          {this.validator.message(
                            "password",
                            this.state.data.password,
                            "required"
                          )}
                        </div>
                        <div className="row m-login__form-sub">
                          <div className="col m--align-right">
                            <a
                              href="#x"
                              id="m_login_forget_password"
                              onClick={this.isForgotPass}
                              className="m-link"
                            >
                              Forget Password ?
                            </a>
                          </div>
                        </div>
                        <div className="m-login__form-action">
                          <button
                            type="submit"
                            id="m_login_signin_submit"
                            className={`btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air ${
                              loader
                                ? "m-loader m-loader--right m-loader--light"
                                : ""
                            }`}
                            disabled={loader ? "disabled" : ""}
                          >
                            Sign In
                          </button>
                        </div>
                      </form>
                    ) : (
                      <form
                        className="m-login__form m-form m-login--forget-password"
                        onSubmit={this.handleForgotPassSubmit}
                      >
                        <div className="form-group m-form__group">
                          <input
                            className="form-control m-input"
                            type="text"
                            placeholder="Email"
                            name="forgot_email"
                            onChange={this.handleChange}
                            value={this.state.forgot_email}
                            ref={forgot_email => (this.input = forgot_email)}
                          />

                          {this.forgotValidator.message(
                            "forgot_email",
                            this.state.data.forgot_email,
                            "required|email"
                          )}
                          {/* <div className="srv-validation-message">
                            {errors.password && errors.password.message
                              ? errors.password.message
                              : ""}
                          </div> */}
                        </div>
                        <div className="m-login__form-action">
                          <button
                            type="submit"
                            id="m_login_forget_password_submit"
                            className={`btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air ${
                              loader
                                ? "m-loader m-loader--right m-loader--light"
                                : ""
                            }`}
                            disabled={loader ? "disabled" : ""}
                          >
                            Request
                          </button>
                          <input
                            type="button"
                            id="m_login_forget_password_cancel"
                            onClick={this.isForgotPassCancel}
                            className="btn btn-outline-focus m-btn m-btn--pill m-btn--custom"
                            value="Cancel"
                          />
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
              <div className="m-stack__item m-stack__item--center"></div>
            </div>
          </div>
          <div
            className="m-grid__item m-grid__item--fluid m-grid m-grid--center m-grid--hor m-grid__item--order-tablet-and-mobile-1	m-login__content"
            style={{
              backgroundImage: "url(../../../assets/app/media/img//bg/bg-4.jpg)"
            }}
          >
            <div className="m-grid__item m-grid__item--middle"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    forgotPass: state.forgotPass
  }),
  dispatch => ({
    dispatch,
    login: credentials => dispatch(loginAction(credentials)),
    forgotPass: credentials => dispatch(forgotPassAction(credentials))
  })
)(Login);
