import axios from 'axios'
import apiurl from '../config/api'
//import { SetLocalStorage, removeLocalStorage } from "./SMILocalStorage";

export function createType(credentials) {
  return axios.post(apiurl.createType, credentials)
    .then(result => {
      return result.data.data
    })
}

export function getDeviceType() {
  return axios.get(apiurl.getDeviceType)
    .then(result => {
      return result.data.data
    })
}

export function getDefaultDeviceType() {
  return axios.get(apiurl.getDefaultDeviceType)
    .then(result => {
      console.log("Default Devices: ", result.data.data)
      return result.data.data
    })
}

export function getDefaultCapabilities() {
  return axios.get(apiurl.getDefaultCapabilities)
    .then(result => {
      return result.data.data
    })
}