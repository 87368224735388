import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { getUserAction, updateUserAction } from "../../../actions/userActions";
import { IconButton } from "@material-ui/core";
import {
  EditOutlined as Edit,
  DeleteOutlineOutlined as Delete,
} from "@material-ui/icons";
import SimpleReactValidator from "simple-react-validator";
import { DEVICELIMIT } from "../../../constants/common";

class UserList extends Component {
  componentDidMount() {
    this.props.getUsers();
  }

  constructor(props) {
    super(props);
    this.state = {
      open: true,
      data: {
        id: "",
        device_limit: "",
      },
      errors: {},
      openModel: true,
      userRowEditData: {},
    };
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  state = {
    isOpen: false,
  };

  openModal = () => {
    this.setState({ isOpen: true });
  };

  handleGetUsers = (value) => {
    const { users } = this.props;
    this.setState({ data: { id: value } });
    this.openModal();
    users.users
      .filter((userDetails) => userDetails._id === value)
      .map((userDetails) => {
        const userData = Object.assign({}, userDetails);
        // console.log("userDetails", userData);
        // return userData;
        this.setState({ userRowEditData: userData }, () => {
          if (this.state.userRowEditData.device_limit >= 0) {
            this.setState({
              data: {
                ...this.state.data,
                device_limit: this.state.userRowEditData.device_limit,
              },
            });
          } else {
            this.setState({
              data: {
                ...this.state.data,
                device_limit: DEVICELIMIT,
              },
            });
          }
        });
      });
  };

  closeModal = () => {
    this.setState({ isOpen: false });
    this.validator.hideMessages();
    // console.log(this.state.data);
  };

  handleDeviceLimit = (e) => {
    e.preventDefault();
    const { data } = this.state;
    if (this.validator.allValid()) {
      this.props.updateUser(data).then((userData) => {
        if (userData.data && userData.data.error) {
          this.setState({
            data: this.state.data,
            errors: userData.data,
          });
        } else {
          this.setState({
            errors: {},
          });
          this.closeModel.click();
        }
        this.props.getUsers();
      });
      //   .catch((err) => console.log("Failure", err));
    } else {
      this.validator = new SimpleReactValidator({
        messages: {
          device_limit: 'Validation has failed!',
          // OR
          default: 'The device limit field value should be greater than zero.'  // will override all messages
        },
      })
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  render() {
    const { users } = this.props;
    const columns = [
      {
        label: "No.",
        name: "",
        options: {
          sort: false,
          customBodyRender: (value, tableMeta) => {
            return tableMeta.rowIndex + 1;
          },
        },
      },
      {
        label: "Name",
        name: "name",
        sortable: true,
      },
      {
        label: "Mobile No.",
        name: "mobileNo",
        sortable: true,
      },
      {
        label: "Email",
        name: "email",
        sortable: true,
      },
      {
        label: "Device Limit",
        name: "device_limit",
        sortable: true,
        options: {
          customBodyRender: (value) => {
            if (value >= 0) {
              return value;
            } else {
              return DEVICELIMIT;
            }
          },
        },
      },
      {
        label: "Device Count",
        name: "user_devices_count",
        sortable: true,
        // options: {
        //   customBodyRender: (value) => {
        //     if (value) {
        //       return value;
        //     } else {
        //       return DEVICELIMIT;
        //     }
        //   },
        // },
      },
      {
        label: "Shared Device Count",
        name: "user_devices_shared",
        sortable: true,
        // options: {
        //   customBodyRender: (value) => {
        //     if (value) {
        //       return value;
        //     } else {
        //       return DEVICELIMIT;
        //     }
        //   },
        // },
      },
      {
        label: "Created",
        name: "createdAt",
        sortable: true,
        options: {
          customBodyRender: (value) => {
            const localDate = moment
              .utc(value)
              .local()
              .format("DD-MM-YYYY hh:mm:s A");
            return localDate;
          },
        },
      },
      {
        label: "Options",
        name: "_id",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value) => {
            return (
              <div style={{ width: "51px" }}>
                {/* {console.log("valuue", value)} */}
                <IconButton
                  size="small"
                  edge="end"
                  color="primary"
                  onClick={() => {
                    this.handleGetUsers(value);
                  }}
                >
                  <Edit />
                </IconButton>
              </div>
            );
          },
        },
      },
    ];
    const data = users && users.users && users.users.length ? users.users : [];
    const totalUser = users && users.totalUser ? users.totalUser : 0;
    const thisWeek = users && users.thisWeek ? users.thisWeek : 0;
    const thisMonth = users && users.thisMonth ? users.thisMonth : 0;
    const options = {
      selectableRows: "none",
      download: false,
      print: false,
      viewColumns: false,
      filter: false,
      customToolbar: () => {
        return (
          <>
            <span className="m--font-bold">
              Total : {totalUser} | Registered last 7 days : {thisWeek} |
              Registered last 30 days : {thisMonth}
            </span>
          </>
        );
      },
    };
    return (
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content">
          <div className="m-portlet m-portlet--mobile">
            <MUIDataTable
              title={"User List"}
              data={data}
              columns={columns}
              options={options}
            />
            <Modal show={this.state.isOpen} onHide={this.closeModal}>
              <Modal.Header closeButton>
                <Modal.Title>Add Device Limit</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form onSubmit={this.handleDeviceLimit}>
                  <div className="form-group">
                    <label className="form-control-label">Device limit</label>
                    <input
                      name="device_limit"
                      maxLength={5}
                      type="text"
                      className="form-control"
                      id="device_limit"
                      // defaultValue={DEVICELIMIT}
                      value={this.state.data.device_limit}
                      // maxLength="6"
                      onChange={this.handleChange}
                    />
                    {this.validator.message(
                      "device_limit",
                      this.state.data.device_limit,
                      "required|integer|regex:^[1-9]*$"
                    )}
                    {/* <div className="srv-validation-message">
                      {errors.error && errors.error.device_limit.message
                        ? errors.error.device_limit.message
                        : ""}
                    </div> */}
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      onClick={() => {
                        this.closeModal();
                        this.setState({ data: { device_limit: null } });
                      }}
                      ref={(input) => (this.closeModel = input)}
                    >
                      Close
                    </button>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </div>
        </div>
        {console.log("this.state", this.state)}
      </div>
    );
  }
}
export default connect(
  (state) => ({
    users: state.users,
  }),
  (dispatch) => ({
    dispatch,
    getUsers: () => dispatch(getUserAction()),
    updateUser: (userData) => dispatch(updateUserAction(userData)),
  })
)(UserList);
