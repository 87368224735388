import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import {
  getDeviceAction,
  blockDeviceAction
} from "../../../actions/deviceWhitelistActions";

class DeviceList extends Component {
  constructor(props) {
    super(props);
    this.handleInactive = this.handleInactive.bind(this);
  }

  componentDidMount() {
    this.props.getDevices();
  }

  handleInactive = e => {
    const deviceId = {
      deviceId: e.target.id,
      isActive: e.target.value === "true" ? false : true
    };
    this.props
      .blockDevice(deviceId)
      .then(() => {
        this.props.getDevices();
      })
      .catch(err => console.log("Failure", err));
  };

  render() {
    const { whitelistDevices } = this.props;
    const columns = [
      {
        label: "id",
        name: "_id",
        options: {
          display: "false"
        }
      },
      {
        label: "No.",
        name: "",
        sortable: true,
        options: {
          sort: false,
          customBodyRender: (value, tableMeta) => {
            return tableMeta.rowIndex + 1;
          }
        }
      },
      {
        label: "UUID",
        name: "UUID",
        sortable: true
      },
      {
        label: "Manufacturer",
        name: "ManufacturerId.name",
        sortable: true
      },
      {
        label: "Status",
        name: "isActive",
        sortable: true,
        options: {
          customBodyRender: value => {
            if (value === true) {
              return "Active";
            } else if (value === false) {
              return "InActive";
            } else {
              return "";
            }
          }
        }
      },
      {
        label: "Created by",
        name: "user.name",
        sortable: true
      },
      {
        label: "Created On",
        name: "createdAt",
        sortable: true,
        options: {
          customBodyRender: value => {
            const localDate = moment
              .utc(value)
              .local()
              .format("DD-MM-YYYY hh:mm:ss A");
            return localDate;
          }
        }
      },
      {
        label: "Updated On",
        name: "updatedAt",
        sortable: true,
        options: {
          customBodyRender: value => {
            const localDate = moment
              .utc(value)
              .local()
              .format("DD-MM-YYYY hh:mm:ss A");
            return localDate;
          }
        }
      },
      {
        label: "Action",
        name: "isActive",
        sortable: false,
        options: {
          sort: false,
          searchable: false,
          customBodyRender: (value, tableMeta) => {
            return (
              <button
                onClick={this.handleInactive}
                id={tableMeta.rowData[0] ? tableMeta.rowData[0] : null}
                className={
                  value ? "btn btn-sm btn-primary" : "btn btn-sm btn-danger"
                }
                value={value}
              >
                {value ? "Block" : "Unblock"}
              </button>
            );
          }
        }
      }
    ];

    const data =
      whitelistDevices &&
      whitelistDevices.whitelistDevices &&
      whitelistDevices.whitelistDevices.length
        ? whitelistDevices.whitelistDevices
        : [];
    const totalDevices =
      whitelistDevices && whitelistDevices.totalDevices
        ? whitelistDevices.totalDevices
        : 0;
    const activeDevice =
      whitelistDevices && whitelistDevices.activeDevice
        ? whitelistDevices.activeDevice
        : 0;
    const inActiveDevice =
      whitelistDevices && whitelistDevices.inActiveDevice
        ? whitelistDevices.inActiveDevice
        : 0;

    const options = {
      selectableRows: "none",
      download: false,
      print: false,
      viewColumns: false,
      filter: false,
      customToolbar: () => {
        return (
          <>
            <span className="m--font-bold">
              Total : {totalDevices} | Active : {activeDevice} | InActive :
              {inActiveDevice}
            </span>
            &nbsp;
            <Link to="/device-whitelist/add" className="btn btn-success">
              Add Devices
            </Link>
          </>
        );
      }
    };

    return (
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content">
          <div className="m-portlet m-portlet--mobile">
            <MUIDataTable
              title={"White Listed Devices"}
              data={data}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default connect(
  state => ({
    whitelistDevices: state.whitelistDevices
  }),
  dispatch => ({
    dispatch,
    getDevices: () => dispatch(getDeviceAction()),
    blockDevice: deviceId => dispatch(blockDeviceAction(deviceId))
  })
)(DeviceList);
