import BaseLayout from "./containers/layout/BaseLayout";
import UserContainer from "./containers/user/list/UserList";
import TokenContainer from "./containers/token/list/TokenList";
import TokenAddContainer from "./containers/token/add/TokenAdd";
import DeviceContainer from "./containers/device/list/DeviceList";
import DeviceTypeContainer from "./containers/deviceType/list/DeviceTypeList";
import DeviceTypeAddContainer from "./containers/deviceType/add/DeviceTypeAdd";
import DeviceWhitelistContainer from "./containers/deviceWhitelist/list/DeviceList";
import DeviceWhitelistAddContainer from "./containers/deviceWhitelist/add/DeviceAdd";
import PartnerListContainer from "./containers/partners/index";
import DeviceManufacturerContainer from "./containers/deviceManufacturer/index";
import CertLogsContainer from "./containers/certLogs/CertLogsList";
import VersionInfoContainer from "./containers/versionInfo/VersionInfoList";

import CommandContainer from "./containers/commandCenter/list/CommandList";

const routes = [
  { path: "/", name: "Home", component: BaseLayout },
  {
    path: "/device-whitelist",
    excat: true,
    icon: "flaticon-settings",
    name: "Device Whitelist",
    component: DeviceWhitelistContainer,
    isSidenav: true
  },
  {
    path: "/device-whitelist/add",
    excat: true,
    name: "Add Device To Whitelist",
    component: DeviceWhitelistAddContainer,
    isSidenav: false
  },
  {
    path: "/device-type",
    excat: true,
    icon: "flaticon-list-2",
    name: "Device Type",
    component: DeviceTypeContainer,
    isSidenav: true
  },
  {
    path: "/device-type/add",
    excat: true,
    name: "Add Device Type",
    component: DeviceTypeAddContainer,
    isSidenav: false
  },
  {
    path: "/devices",
    excat: true,
    icon: "flaticon-computer",
    name: "Device Owner",
    component: DeviceContainer,
    isSidenav: true
  },
  {
    path: "/user",
    excat: true,
    icon: "flaticon-user",
    name: "Users",
    component: UserContainer,
    isSidenav: true
  },
  {
    path: "/oauth-token",
    excat: true,
    icon: "flaticon-lock",
    name: "OAuth",
    component: TokenContainer,
    isSidenav: true
  },
  {
    path: "/command-center",
    excat: true,
    icon: "flaticon-interface-2",
    name: "Command Center",
    component: CommandContainer,
    isSidenav: true
  },
  {
    path: "/oauth-token/add",
    excat: true,
    name: "token-add",
    component: TokenAddContainer,
    isSidenav: false
  },
  {
    path: "/oauth-token/:id/edit",
    excat: true,
    name: "token-add",
    component: TokenAddContainer,
    isSidenav: false
  },
  {
    path: "/device-manufacturer",
    excat: true,
    icon: "flaticon-truck",
    name: "Device manufacturer",
    component: DeviceManufacturerContainer,
    isSidenav: true
  },
  {
    path: "/partners",
    excat: true,
    icon: "flaticon-user",
    name: "Partners",
    component: PartnerListContainer,
    isSidenav: true
  },
  {
    path: "/certificate-logs",
    excat: true,
    icon: "flaticon-open-box",
    name: "Certificate logs",
    component: CertLogsContainer,
    isSidenav: true
  },
  {
    path: "/version-info",
    excat: true,
    icon: "flaticon-map",
    name: "Version info",
    component: VersionInfoContainer,
    isSidenav: true
  },
];

export default routes;
