
import React from 'react';
import { Snackbar } from "@material-ui/core";
import { connect } from "react-redux";
import { Alert } from "@material-ui/lab";
import { closeSnackbar } from "../actions/globalAction";

const SnackBar = ({ open, type, message, closeSnackbar }) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") { return; }
    closeSnackbar();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}>
      <Alert
        onClose={handleClose}
        elevation={6}
        variant="filled"
        severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};

const mapStateToProps = ({ global }) => ({
  open: global.snackbar.open,
  type: global.snackbar.type,
  message: global.snackbar.message
});

export default connect(mapStateToProps, { closeSnackbar })(SnackBar);
