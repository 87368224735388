import * as tokenService from "../services/TokenService";
export const CLIENT_CREATED = "CLIENT_CREATED";
export const CLIENT_LOADED = "CLIENT_LOADED";
export const TOKEN_LOADED = "TOKEN_LOADED";
export const REVOKE_TOKEN = "REVOKE_TOKEN";
export const SEND_OTP = "SEND_OTP";
export const VERIFY_OTP = "VERIFY_OTP";
export const GET_OAUTH_CLIENT = "GET_OAUTH_CLIENT";
export const GET_OAUTH_PARTNER = "GET_OAUTH_PARTNER";

export function createTokenAction(credentials) {
  return (dispatch) => {
    return tokenService.createClient(credentials).then((result) => {
      dispatch(createClientInAction(result));
      return result;
    });
  };
}

export function createClientInAction(client) {
  return {
    type: CLIENT_CREATED,
    client,
  };
}

export function revokeTokenAction(tokenId) {
  return (dispatch) => {
    return tokenService.revokeToken(tokenId).then((token) => {
      dispatch({
        type: REVOKE_TOKEN,
        token,
      });
    });
  };
}

export const getTokenAction = () => {
  return (dispatch) => {
    return tokenService.getClient().then((clients) => {
      dispatch({
        type: CLIENT_LOADED,
        clients,
      });
      return clients;
    });
  };
};

export const getAuthTokenAction = () => {
  return (dispatch) => {
    return tokenService.getAuthTokens().then((tokens) => {
      dispatch({
        type: TOKEN_LOADED,
        tokens,
      });
      return tokens;
    });
  };
};

export function sendOtpAction() {
  return (dispatch) => {
    return tokenService.sendOtp().then((authUser) => {
      dispatch({
        type: SEND_OTP,
        authUser,
      });
      return authUser;
    });
  };
}

export function verifyOtpAction(data) {
  return (dispatch) => {
    return tokenService.verifyOtp(data).then((otpData) => {
      dispatch({
        type: VERIFY_OTP,
        otpData,
      });
      return otpData;
    });
  };
}

export function getClientAction(data) {
  return (dispatch) => {
    return tokenService.getAuthClient(data).then((client) => {
      dispatch({
        type: GET_OAUTH_CLIENT,
        client,
      });
      return client;
    });
  };
}

export function getPartnerAction(data) {
  return (dispatch) => {
    return tokenService.getPartner(data).then((partner) => {
      dispatch({
        type: GET_OAUTH_PARTNER,
        partner,
      });
      return partner;
    });
  };
}
