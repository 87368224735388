import axios from 'axios';
import api from '../config/api'

export const ActionTypes = {
    VERSION_INFO_LOADED: 'VERSION_INFO_LOADED',
}

/**
 * Get Logs
 */
export const getInfo = () => async dispatch => {
    axios.get(api.versionInfo)
        .then(result => {            
            dispatch({
                type: ActionTypes.VERSION_INFO_LOADED,
                versionInfo: result.data.data
            })
        })
}