import * as deviceTypeService from '../services/DeviceTypeService'
export const DEVICE_TYPE_LOADED = 'DEVICE_TYPE_LOADED'
export const DEVICE_DEFAULT_TYPE_LOADED = 'DEVICE_DEFAULT_TYPE_LOADED'
export const DEVICE_DEFAULT_CAPABILITIES_LOADED = 'DEVICE_DEFAULT_CAPABILITIES_LOADED'
export const CREATE_DEVICE_TYPE = 'CREATE_DEVICE_TYPE'

export const getDeviceTypeAction = () => {
  return (dispatch) => {
    deviceTypeService.getDeviceType()
      .then((deviceTypes) => {
        dispatch({
          type: DEVICE_TYPE_LOADED,
          deviceTypes
        })
      })
  }
}

export const getDefaultDeviceTypeAction = () => {
  return (dispatch) => {
    deviceTypeService.getDefaultDeviceType()
      .then((defaultDeviceType) => {
        dispatch({
          type: DEVICE_DEFAULT_TYPE_LOADED,
          defaultDeviceType
        })
      })
  }
}

export const getDefaultCapabilitiesAction = () => {
  return (dispatch) => {
    deviceTypeService.getDefaultCapabilities()
      .then((defaultCapatibility) => {
        dispatch({
          type: DEVICE_DEFAULT_CAPABILITIES_LOADED,
          defaultCapatibility
        })
      })
  }
}

export function createDeviceTypeAction(credentials) {
  return (dispatch) => {
    return deviceTypeService.createType(credentials)
      .then((result) => {
        dispatch(createTypeAction(result))
        return result
      })
  }
}

export function createTypeAction(deviceType) {
  return {
    type: CREATE_DEVICE_TYPE,
    deviceType
  }
}



