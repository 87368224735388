import axios from 'axios'
import apiurl from '../config/api'

export function getWhitelistDevice() {
  return axios.get(apiurl.getWhitelistDevice)
    .then(result => {
      return result.data.data
    })
}

export async function addWhitelistDevices(credentials) {  
  const formData = new FormData();

  await Object.entries(credentials).map(([key, value]) => {
    return formData.append(key, value);
  });

  return axios.post(apiurl.addWhitelistDevice, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then(result => {
    return result.data.data
  })
}

export function blockDevice(deviceId) {
  return axios.patch(`${apiurl.blockDevice}/${deviceId.deviceId}`, deviceId)
    .then(result => {
      return result.data.data
    })
}
