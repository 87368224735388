import * as UserService from '../services/UserService'
export const USER_LOGGED_IN = 'USER_LOGGED_IN'
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'


export function loginAction(credentials) {
  return (dispatch) => {
    return UserService.login(credentials)
      .then((result) => {
        dispatch(userLoggedInAction(result))
        return result
      })
  }
}

export function userLoggedInAction(user) {
  return {
    type: USER_LOGGED_IN,
    user
  }
}

export function logoutAction() {
  return (dispatch) => {
    return UserService.logout()
      .then(() => {
        dispatch(userLoggedOutAction())
        return true
      })
  }
}

export function userLoggedOutAction() {
  return {
    type: USER_LOGGED_OUT,
  }
}

export function forgotPassAction(credentials) {
  return (dispatch) => {
    return UserService.forgotPassword(credentials)
      .then((result) => {
        dispatch(forgotPasswordAction(result))
        return result
      })
  }
}

export function forgotPasswordAction(forgotPass) {
  return {
    type: FORGOT_PASSWORD,
    forgotPass
  }
}
