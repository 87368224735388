import { ActionTypes } from '../actions/versionInfoActions'

export function versionInfoReducer(state = null, action) {
  switch (action.type) {

    case ActionTypes.VERSION_INFO_LOADED:
      return action.versionInfo

    default:
      return state
  }
}