import { ActionTypes } from '../actions/deviceManufacturerActions'

export function deviceManufacturerReducer(state = null, action) {
  switch (action.type) {

    case ActionTypes.DEVICE_MANUFACTURER_LOADED:
      return action.deviceManufacturers

    case ActionTypes.DEVICE_MANUFACTURER_DELETE:
      return state.filter(deviceManufacturer => deviceManufacturer._id !== action.payload.Id)

    case ActionTypes.SAVE_DEVICE_MANUFACTURER:
      state.push(action.payload)
      return state


    case ActionTypes.UPDATE_MANUFACTURER:
      state = state.map(manufacturer => manufacturer._id === action.payload._id ? action.payload : manufacturer)
      return state;

    default:
      return state
  }
}