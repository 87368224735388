import * as yup from "yup";

export const Validations = yup.object({
    name: yup.string().required(),
    displayName: yup.string().required(),
    contactPersonName: yup.string().required(),
    businessContactNumber: yup.string().required().min(10),
    businessEmail: yup.string().email().required(),
    supportContactNumber: yup.string().required().min(10),
    supportEmail: yup.string().email().required(),
 
});

export const defaultValue = {
    name: '',
    displayName:'' ,
    contactPersonName:'' ,
    businessContactNumber:'' ,
    businessEmail:'' ,
    supportContactNumber: '',
    supportEmail:'' , 
    deviceTypes:[],
};
