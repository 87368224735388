import * as deviceService from '../services/DeviceService'
export const DEVICE_LOADED = 'DEVICE_LOADED'

export const getDeviceAction = () => {
  return (dispatch) => {
    deviceService.getDevice()
      .then((devices) => {
        dispatch({
          type: DEVICE_LOADED,
          devices
        })
      })
  }
}