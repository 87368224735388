import { ActionTypes } from '../actions/partnerActions'

export function partnerReducer(state = null, action) {
  switch (action.type) {
    case ActionTypes.PARTNER_LOADED:
      return action.partners;

    case ActionTypes.PARTNER_DELETE:
      return state.filter((partner) => partner._id !== action.payload.Id);

    case ActionTypes.SAVE_PARTNER:
      state.push(action.payload);
      return state;

    case ActionTypes.UPDATE_PARTNER:
      state = state.map((partner) =>
        partner._id === action.payload._id ? action.payload : partner
      );
      return state;

    default:
      return state;
  }
}