export const serverUrl = process.env.REACT_APP_SERVER_URL || "";

const baseUrl = process.env.REACT_APP_SERVER_URL || "";

export default {
  loginUrl: `${baseUrl}auth/login`,
  createClient: `${baseUrl}oAuthClient/create`,
  getClient: `${baseUrl}oAuthClient`,
  getAuthClient: `${baseUrl}oAuthClient/detail`,
  getDevice: `${baseUrl}devices`,
  getUsers: `${baseUrl}users`,
  getTokens: `${baseUrl}oAuthClient/getToken`,
  revokeToken: `${baseUrl}oAuthClient/revokeToken`,
  getDeviceType: `${baseUrl}deviceType`,
  getDefaultDeviceType: `${baseUrl}deviceDefaultType`,
  getDefaultCapabilities: `${baseUrl}deviceDefaultCapabilities`,
  createType: `${baseUrl}deviceType/create`,
  getCommands: `${baseUrl}commands`,
  getDeviceTypes: `${baseUrl}commands/deviceType`,
  getDevices: `${baseUrl}commands/devices`,
  sendCommands: `${baseUrl}commands/send`,
  getWhitelistDevice: `${baseUrl}devices/whitelist-devices`,
  addWhitelistDevice: `${baseUrl}import/whitelist-devices`,
  blockDevice: `${baseUrl}devices/whitelist-devices`,
  sendOtp: `${baseUrl}oAuthClient/request-otp`,
  verifyOtp: `${baseUrl}oAuthClient/verify-otp`,
  forgotPassword: `${baseUrl}auth/send-password-reset-link`,
  RefreshToken: `${baseUrl}auth/token`,
  deviceManufacturer: `${baseUrl}device-manufacturer`,
  partner: `${baseUrl}partners`,
  createPartner: `${baseUrl}partners/create`,
  certificateLogs: `${baseUrl}cert-logs`,
  versionInfo: `${baseUrl}default/version-info`,
  getPartner: `${baseUrl}partners`,
  updateUser: `${baseUrl}users/update-device-limit`,
};
