import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import React, { useEffect } from "react";
import { getInfo } from "../../actions/versionInfoActions";
import yaml from '../../config/json2yaml';

/**
 * Certificate logs index
 * 
 * @param {*} param0
 */
const VersionInfoList = ({ getInfo, versionInfo }) => {

  // Get initial values 
  useEffect(() => {
    getInfo();
  }, [getInfo]);

  // Datatable configurations
  const datatable = {
    // INdividual columns customization
    columns: [
      {
        label: "No.",
        name: "",
        options: {
          sort: false,
          customBodyRender: (value, tableMeta) => {
            return tableMeta.rowIndex + 1;
          }
        }
      },
      {
        label: "Version number",
        name: "_id",       
      },
      {
        label: "Total devices",
        name: "count",       
      },  
    ],
    // Set data
    data: versionInfo && versionInfo.length ? versionInfo : [],
    // Set options
    options: {
      selectableRows: "none",
      download: false,
      print: false,
      viewColumns: false,
      filter: false,
    }
  };

  // Return main view
  return (
    <div className="m-grid__item m-grid__item--fluid m-wrapper">
      <div className="m-content">
        <div className="m-portlet m-portlet--mobile">
          <MUIDataTable
            title={"Version Information"}
            data={datatable.data}
            className="preWhiteSpace"
            columns={datatable.columns}           
            options={datatable.options} />
        </div>
      </div>
    </div>
  );

}

const mapStateToProps = state => ( {
    versionInfo: state.versionInfo
 });


export default connect(mapStateToProps,
  dispatch => ({
    dispatch,
    getInfo: () => dispatch(getInfo()),
  })
)(VersionInfoList);
