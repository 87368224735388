import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
//import DataTable from "react-data-table-component";
import MUIDataTable from "mui-datatables";
import { GetLocalStorage } from "../../../services/SMILocalStorage";
import SimpleReactValidator from "simple-react-validator";

import {
  getTokenAction,
  getAuthTokenAction,
  revokeTokenAction,
  sendOtpAction,
  verifyOtpAction,
} from "../../../actions/tokenActions";

class TokenList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      data: {},
      errors: {},
      openModel: true,
    };
    this.handleRevoke = this.handleRevoke.bind(this);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  componentDidMount() {
    const { data } = this.state;
    this.props
      .getTokens(data)
      .then((clients) => {
        if (clients.error) {
          this.setState({
            data: this.state.data,
            errors: clients.error,
          });
        }
      })
      .catch((err) => console.log("Failure", err));

    this.props
      .getAuthTokens(data)
      .then((tokens) => {
        if (tokens.error) {
          this.setState({
            data: this.state.data,
            errors: tokens.error,
          });
        }
      })
      .catch((err) => console.log("Failure", err));
  }

  handleRevoke = (e) => {
    const tokenId = { tokenId: e.target.id };
    this.props
      .revokeToken(tokenId)
      .then(() => {
        this.props.getAuthTokens();
      })
      .catch((err) => console.log("Failure", err));
  };

  handleSendOtp = (e) => {
    this.props.sendOtp();
  };

  handleVerifyOtp = (e) => {
    e.preventDefault();
    const { data } = this.state;
    if (this.validator.allValid()) {
      this.props
        .verifyOtp(data)
        .then((otpData) => {
          if (otpData.data && otpData.data.error) {
            this.setState({
              data: this.state.data,
              errors: otpData.data,
            });
          } else {
            this.setState({
              errors: {},
            });
            this.props.getTokens();
            this.props.getAuthTokens();
            this.closeModel.click();
          }
        })
        .catch((err) => console.log("Failure", err));
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  userDetails = GetLocalStorage("smi-user");
  render() {
    const { clients, tokens } = this.props;
    const { errors } = this.state;

    const columns = [
      {
        label: "id",
        name: "_id",
        sortable: false,
        options: {
          display: "false",
        },
      },
      {
        label: "No.",
        name: "",
        options: {
          sort: false,
          customBodyRender: (value, tableMeta) => {
            return tableMeta.rowIndex + 1;
          },
        },
      },
      {
        label: "Client ID",
        name: "_id",
        sortable: true,
      },
      {
        label: "Name",
        name: "name",
        sortable: true,
        options: {
          sort: false,
          searchable: false,
          customBodyRender: (value, tableMeta) => {
            return (
              <Link to={"oauth-token/" + tableMeta.rowData[0] + "/edit"}>
                {value}
              </Link>
            );
          },
        },
      },
      {
        label: "Display Name",
        name: "display_name",
        sortable: true,
      },
      {
        label: "Secret",
        name: "secret",
        sortable: true,
        expandableRows: true,
      },
      {
        label: "Redirect",
        name: "redirect",
        sortable: true,
        options: {
          customBodyRender: (value) => {
            return value.join();
          },
        },
      },
      {
        label: "Scope",
        name: "scope",
        sortable: false,
        options: {
          customBodyRender: (value) => {
            return value ? "Yes" : "No";
          },
        },
      },
    ];

    const tokenColumns = [
      {
        label: "id",
        name: "_id",
        sortable: false,
        options: {
          display: "false",
        },
      },
      {
        label: "No.",
        name: "",
        options: {
          sort: false,
          customBodyRender: (value, tableMeta) => {
            return tableMeta.rowIndex + 1;
          },
        },
      },
      {
        label: "User",
        name: "userId.name",
        sortable: true,
      },
      {
        label: "Mobile No.",
        name: "userId.mobileNo",
        sortable: true,
      },
      {
        label: "Client Name",
        name: "clientId.name",
        sortable: true,
      },
      {
        label: "Revoke",
        name: "revoked",
        options: {
          sort: false,
          searchable: false,
          customBodyRender: (value, tableMeta) => {
            return !value ? (
              <button
                onClick={this.handleRevoke}
                id={tableMeta.rowData[0] ? tableMeta.rowData[0] : null}
                className="btn btn-sm btn-primary"
              >
                Revoke
              </button>
            ) : (
              <span>Revoked</span>
            );
          },
        },
      },
    ];

    let addClientButton;
    let authText = "";
    let showModel;

    if (
      (errors && errors.verify && errors.verify.message) ||
      (errors.error && errors.error.otp.message)
    ) {
      addClientButton = (
        <button
          type="button"
          className="btn btn-success"
          data-toggle="modal"
          data-target="#verifyOtpModel"
          onClick={this.handleSendOtp}
        >
          Authorise
        </button>
      );
      showModel = true;
      authText = "You are not authorise to show this module";
    } else {
      addClientButton = (
        <Link to="/oauth-token/add" className="btn btn-success">
          New Client
        </Link>
      );
      authText = "Clients";
      showModel = false;
    }

    const clientData = clients && clients.length ? clients : [];
    const options = {
      responsive: "stacked",
      selectableRows: "none",
      download: false,
      print: false,
      viewColumns: false,
      filter: false,
      customToolbar: () => {
        return <>{addClientButton}</>;
      },
    };

    const appTableOptions = {
      selectableRows: "none",
      download: false,
      print: false,
      viewColumns: false,
      filter: false,
      responsive: "stacked",
    };
    const tokenData = tokens && tokens.length ? tokens : [];
    return (
      <>
        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-content">
            <div className="m-portlet m-portlet--mobile">
              <MUIDataTable
                title={authText}
                data={clientData}
                columns={columns}
                options={options}
              />
            </div>
          </div>
          <div className="m-content">
            <div className="m-portlet m-portlet--mobile">
              <MUIDataTable
                title={"Authorized Applications"}
                data={tokenData}
                columns={tokenColumns}
                options={appTableOptions}
              />
            </div>
          </div>
          <div
            className={`modal fade ${showModel ? "show" : ""}`}
            id="verifyOtpModel"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-sm" role="document">
              <div className="modal-content">
                <form onSubmit={this.handleVerifyOtp}>
                  <div className="modal-header">
                    <h5 className="modal-title">
                      OTP sent to your mobile number : XXXXXXX
                      {/* {this.userDetails.user.mobileNumber.slice(-3)} */}
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="form-group">
                      <label className="form-control-label">Enter OTP :</label>
                      <input
                        name="otp"
                        type="text"
                        className="form-control"
                        id="recipient-name"
                        maxLength="6"
                        onChange={this.handleChange}
                      />
                      {this.validator.message(
                        "otp",
                        this.state.data.otp,
                        "required|size:6|integer"
                      )}
                      <div className="srv-validation-message">
                        {errors.error && errors.error.otp.message
                          ? errors.error.otp.message
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      ref={(input) => (this.closeModel = input)}
                    >
                      Close
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Verify OTP
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default connect(
  (state) => ({
    clients: state.clients,
    tokens: state.tokens,
    authUser: state.authUser,
    verifyOtpData: state.verifyOtpData,
  }),
  (dispatch) => ({
    dispatch,
    getTokens: () => dispatch(getTokenAction()),
    getAuthTokens: () => dispatch(getAuthTokenAction()),
    revokeToken: (tokenId) => dispatch(revokeTokenAction(tokenId)),
    sendOtp: () => dispatch(sendOtpAction()),
    verifyOtp: (otpData) => dispatch(verifyOtpAction(otpData)),
  })
)(TokenList);
