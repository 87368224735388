import React from "react";
import { Redirect } from "react-router";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import routes from "../../routes";
import Footer from "./Footer";
import Header from "./Header";
import SideNav from "./SideNav";

const BaseLayout = props => {
  /**
   * check if logged in
   */
  const isAuthenticate = () => {
    return JSON.parse(localStorage.getItem("smi-user"));
  };

  if (isAuthenticate()) {
    return (
      <div
        style={{ height: "100%" }}
        className="m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default"
      >
        <div className="m-grid m-grid--hor m-grid--root m-page">
          <Header {...props} />
          <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
            <SideNav {...props} routes={routes}></SideNav>
            <Switch>
              {routes.map((route, idx) => {
                return route.component ? (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.excat}
                    render={props => {
                      return <route.component {...props} />;
                    }}
                  />
                ) : (
                  undefined
                );
              })}
              <Redirect from="/" to="/devices" />
            </Switch>
          </div>
          <Footer />
        </div>
      </div>
    );
  } else {
    return <Redirect to="/login" />;
  }
};

export default connect(null, null)(BaseLayout);
