import { ActionTypes } from '../actions/certificateLogsActions'

export function certLogsReducer(state = null, action) {
  switch (action.type) {

    case ActionTypes.CERTIFICATE_LOGS_LOADED:
      return action.certLogs

    default:
      return state
  }
}