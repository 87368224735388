import { combineReducers } from "redux";
import { authReducer } from './authReducer'
import { authClientReducer, authSendOtpReducer, authVerifyOtpReducer } from './authClientReducer'
import { authTokenReducer } from './authTokenReducer'
import { deviceReducer } from './deviceReducer'
import { userReducer } from './userReducer'
import { deviceTypesReducer, deviceDefaultTypesReducer, deviceDefaultCapatibilityReducer, createTypeReducer } from './deviceTypeReducer'
import { getDevicesReducer, getCommandsReducer, deviceAdminTypesReducer } from './commandReducer'
import { createWhitelistReducer, getWhitelistDeviceReducer, blockDeviceReducer } from './deviceWhitelistReducer'
import { forgotPassReducer } from './forgotPassReducer'
import { deviceManufacturerReducer } from './deviceManufacturerReducer';
import {partnerReducer} from './partnerReducer'
import { certLogsReducer } from './cerLogsReducer';
import { versionInfoReducer } from './versionInfoReducer';
import global from './globalReducer'

export default combineReducers({
    user: authReducer,
    clients: authClientReducer,
    devices: deviceReducer,
    users: userReducer,
    tokens: authTokenReducer,
    deviceTypes: deviceTypesReducer,
    defaultDeviceType: deviceDefaultTypesReducer,
    defaultCapatibility: deviceDefaultCapatibilityReducer,
    deviceType: createTypeReducer,
    deviceAdminType: deviceAdminTypesReducer,
    commands: getCommandsReducer,
    deviceslist: getDevicesReducer,
    whitelistDevices: getWhitelistDeviceReducer,
    addedWhitelistDevices: createWhitelistReducer,
    blockedDevice: blockDeviceReducer,
    authUser: authSendOtpReducer,
    otpData: authVerifyOtpReducer,
    forgotPass: forgotPassReducer,
    deviceManufacturers: deviceManufacturerReducer,
    partners: partnerReducer,
    certLogs: certLogsReducer,
    versionInfo:versionInfoReducer,
    global
});
