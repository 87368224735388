import axios from 'axios'
import apiurl from '../config/api'
import { SetLocalStorage, removeLocalStorage } from "./SMILocalStorage";

export function login(credentials) {
  return axios.post(apiurl.loginUrl, credentials)
    .then(result => {
      SetLocalStorage("smi-user", result.data.data);
      // const { access_token } = result.data.data;
     // axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
      return result.data.data
    }).catch(error => {
      console.log(error)
      return error.response.data;
    });
}

export async function logout() {
  removeLocalStorage('smi-user');
  return true;
}

export function getCurrentUser() {
  return JSON.parse(localStorage.getItem('user'))
}

export function forgotPassword(credentials) {
  axios.post(apiurl.forgotPassword, credentials)
    .then(result => {
      return result.data
    });
}
