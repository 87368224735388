import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./containers/login/Login";
import BaseLayout from "./containers/layout/BaseLayout";
import SnackBar from "./components/Snackbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

require('dotenv').config();

const App = () => {

  return (
    <>

        <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnFocusLoss
        pauseOnHover
        />
      <Router>
        <Switch>
          <Route path="/login" exact={true} component={Login} />
          <Route path="/" component={BaseLayout} />
        </Switch>
      </Router>
      <SnackBar></SnackBar>
    </>
  );

};

export default App;
