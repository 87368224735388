import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import React, { useEffect } from "react";
import { getLogs } from "../../actions/certificateLogsActions";
import yaml from '../../config/json2yaml';
import moment from 'moment';
import { Button } from "@material-ui/core";

/**
 * Certificate logs index
 * 
 * @param {*} param0
 */
const CertLogsList = ({ getLogs, certLogs }) => {

  // Get initial values 
  useEffect(() => {
    getLogs();

  }, [getLogs]);

  // Datatable configurations
  const datatable = {
    // INdividual columns customization
    columns: [
      {
        label: "No.",
        name: "",
        options: {
          sort: false,
          customBodyRender: (value, tableMeta) => {
            return tableMeta.rowIndex + 1;
          }
        }
      },
      {
        label: "Request data",
        name: "requestData",
        options: {
          sort: false,
          customBodyRender: (value, tableMeta) => {
          return yaml.json2yaml(value);
          }
        }
      },
      {
        label: "Header",
        name: "header",
        options: {
          sort: false,
          customBodyRender: (value, tableMeta) => {
            return yaml.json2yaml(value);
          }
        }
      },
      {
        label: "Success",
        name: "success",
        options: {
          sort: false,
          customBodyRender: (value, tableMeta) => {
            return (value) ? "Success" : "Failed";  
          }
        }
      },
      {
        label: "Geo location",
        name: "clientInfo",
        options: {
          sort: false,
          customBodyRender: (value, tableMeta) => {
            return yaml.json2yaml(value);
          }
        }
      },
      {
        label: "Created On",
        name: "createdAt",
        sortable: true,
        options: {
          customBodyRender: value => {
            const localDate = moment
              .utc(value)
              .local()
              .format("DD-MM-YYYY hh:mm:s A");
            return localDate;
          }
        }
      }
    ],
    // Set data
    data: certLogs && certLogs.length ? certLogs : [],
    // Set options
    options: {
      selectableRows: "none",
      download: false,
      print: false,
      viewColumns: false,
      filter: false,
    }
  };

  // Return main view
  return (
    <div className="m-grid__item m-grid__item--fluid m-wrapper">
      <div className="m-content">
        <div className="m-portlet m-portlet--mobile">
          <MUIDataTable
            title={"Certificate Logs"}
            data={datatable.data}
            className="preWhiteSpace"
            columns={datatable.columns}           
            options={datatable.options} />
        </div>
      </div>
    </div>
  );

}

const mapStateToProps = state => ({
  certLogs: state.certLogs
});


export default connect(mapStateToProps,
  dispatch => ({
    dispatch,
    getLogs: () => dispatch(getLogs()),
  })
)(CertLogsList);
