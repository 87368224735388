import React, { Component } from "react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import {
  sendCommandAction,
  getTypesAction,
  getDevicesAction,
  getCommandsAction
} from "../../../actions/commandActions";

class CommandList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        deviceType: "",
        deviceId: "",
        speed: 5,
        onoff: ""
      },
      loading: false,
      showSpeed: false,
      errors: {}
    };
    this.handleCommandChange = this.handleCommandChange.bind(this);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  componentDidMount() {
    this.props.getType();
    this.props.getDevices();
    this.props.getCommands();
  }

  getInitialState = () => {
    return {
      data: {
        deviceType: "",
        deviceId: "",
        speed: 5,
        onoff: ""
      },
      loading: false,
      showSpeed: false,
      errors: {}
    };
  };

  handleSubmit = e => {
    e.preventDefault();
    const { data } = this.state;
    if (this.validator.allValid()) {
      this.setState({
        data: {
          ...this.state.data,
          [e.target.name]: e.target.value
        },
        loading: true
      });
      this.props
        .sendCommand(data)
        .then(() => {
          this.setState({
            loading: false
          });
        })
        .catch(err => console.log("Failure", err));
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleChange = e => {
    if (e.target.name === "deviceType") {
      if (e.target.value === "Fan") {
        this.setState({ showSpeed: true });
      } else {
        this.setState({ showSpeed: false });
      }
    }
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value
      }
    });
  };

  handleCommandChange(e) {
    if (e.target.type === "checkbox") {
      this.setState({
        data: {
          ...this.state.data,
          [e.target.name]: e.target.checked
        }
      });
    } else {
      this.setState({
        data: {
          ...this.state.data,
          [e.target.name]: e.target.value
        }
      });
    }
  }

  render() {
    const { deviceAdminType } = this.props;
    const { deviceslist } = this.props;
    const { commands } = this.props;
    const loader = this.state.loading;
    const data = commands && commands.length ? commands : [];
    const columns = [
      {
        label: "No.",
        name: "",
        options: {
          sort: false,
          customBodyRender: (value, tableMeta) => {
            return tableMeta.rowIndex + 1;
          }
        }
      },
      {
        label: "Type",
        name: "type",
        sortable: true
      },
      {
        label: "Time",
        name: "ts",
        sortable: true,
        options: {
          customBodyRender: value => {
            const localDate = moment
              .utc(value)
              .local()
              .format("DD-MM-YYYY hh:mm:s A");
            return localDate;
          }
        }
      },
      {
        label: "Data",
        name: "data",
        options: {
          customBodyRender: value => {
            return JSON.stringify(value);
          }
        }
      }
    ];

    const options = {
      selectableRows: "none",
      download: false,
      print: false,
      viewColumns: false,
      filter: false
    };
    return (
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content">
          <div className="m-portlet m-portlet--mobile">
            <div className="m-portlet__head">
              <div className="m-portlet__head-caption">
                <div className="m-portlet__head-title">
                  <h3 className="m-portlet__head-text">Command Center</h3>
                </div>
              </div>
            </div>
            <div className="m-portlet__body">
              <form
                className="m-form m-form--fit m-form--label-align-right m-form--group-seperator"
                onSubmit={this.handleSubmit}
              >
                <div className="m-portlet__body">
                  <div className="m-form__section m-form__section--first">
                    <div className="form-group m-form__group row">
                      <label className="col-lg-2 col-form-label">
                        Device Type:
                      </label>
                      <div className="col-lg-6">
                        <select
                          className="form-control m-input"
                          name="deviceType"
                          onChange={this.handleChange}
                        >
                          <option key="0" value="">
                            Select Type
                          </option>
                          {deviceAdminType &&
                            deviceAdminType.map((deviceType, index) => {
                              return (
                                <option
                                  key={index}
                                  value={
                                    deviceType.deviceType &&
                                    deviceType.deviceType.name
                                      ? deviceType.deviceType.name
                                      : ""
                                  }
                                >
                                  {deviceType.name}
                                </option>
                              );
                            })}
                        </select>
                        {this.validator.message(
                          "deviceType",
                          this.state.data.deviceType,
                          "required"
                        )}
                      </div>
                    </div>
                    <div className="form-group m-form__group row">
                      <label className="col-lg-2 col-form-label">
                        Device ID:
                      </label>
                      <div className="col-lg-6">
                        <select
                          className="form-control m-input"
                          name="deviceId"
                          onChange={this.handleChange}
                        >
                          <option key="0" value="">
                            Select Device
                          </option>
                          {deviceslist &&
                            deviceslist.map((device, index) => {
                              return (
                                <option key={index} value={device.UUID}>
                                  {device.UUID}
                                </option>
                              );
                            })}
                        </select>
                        {this.validator.message(
                          "deviceId",
                          this.state.data.deviceId,
                          "required"
                        )}
                      </div>
                    </div>
                    <div
                      id="deviceOnOff"
                      className="form-group m-form__group row"
                    >
                      <label className="col-lg-2 col-form-label">On/Off:</label>
                      <div className="col-lg-6">
                        <label className="switch">
                          <input
                            name="onoff"
                            type="checkbox"
                            onChange={this.handleCommandChange}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    {this.state.showSpeed ? (
                      <div
                        id="deviceSpeed"
                        className="form-group m-form__group row"
                      >
                        <label className="col-lg-2 col-form-label">
                          Speed:
                        </label>
                        <div className="col-lg-2">
                          <div className="speedslider">
                            <input
                              name="speed"
                              type="range"
                              min="1"
                              max="5"
                              step="1"
                              className="slider"
                              id="myRange"
                              onChange={this.handleCommandChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-1 col-form-label">
                          <span>{this.state.data.speed}</span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="m-portlet__foot m-portlet__foot--fit">
                  <div className="m-form__actions m-form__actions">
                    <div className="row">
                      <div className="col-lg-2 col-form-label"></div>
                      <div className="col-lg-6">
                        <button
                          type="submit"
                          className={`btn btn-primary ${
                            loader
                              ? "m-loader m-loader--right m-loader--light"
                              : ""
                          }`}
                        >
                          Send Command
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="m-content">
          <div className="m-portlet m-portlet--mobile">
            {/*  <div className="m-portlet__head">
              <div className="m-portlet__head-caption">
                <div className="m-portlet__head-title">
                  <h3 className="m-portlet__head-text">Command Log</h3>
                </div>
              </div>
            </div> */}
            <MUIDataTable
              title={"Command Log"}
              data={data}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    deviceAdminType: state.deviceAdminType,
    deviceslist: state.deviceslist,
    commands: state.commands
  }),
  dispatch => ({
    dispatch,
    getType: () => dispatch(getTypesAction()),
    getDevices: () => dispatch(getDevicesAction()),
    getCommands: () => dispatch(getCommandsAction()),
    sendCommand: commandData => dispatch(sendCommandAction(commandData))
  })
)(CommandList);
