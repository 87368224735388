import React from "react";
import {Button} from "react-bootstrap";

const LoadingSubmit = ({isLoading, type, text, className}) => {
    return (
        <>
            {isLoading && (<Button type={type} disabled={true} style={{paddingRight: "3.5rem"}}
                                   className={`btn btn-primary position-r btn-elevate kt-spinner kt-spinner--light  kt-spinner kt-spinner--right ${className}`}>{text}</Button>)}

            {!isLoading && (<Button type={type} className={`btn btn-primary position-r btn-elevate ${className}`}>{text}</Button>)}
        </>
    )
}

LoadingSubmit.defaultProps = {
    type: "submit",
    text: "Save",
    isLoading: false,
    className: ""
}

export default LoadingSubmit