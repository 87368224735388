import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { getDeviceManufacturer, deleteManufacturer } from "../../actions/deviceManufacturerActions";
import { Dialog, DialogTitle, Button, DialogActions, DialogContent, DialogContentText, IconButton } from "@material-ui/core";
import CreateEditModal from "./addEditModal";
import { defaultValue, Validations } from "./Validations";
import { Add, EditOutlined as Edit, DeleteOutlineOutlined as Delete } from "@material-ui/icons";

/**
 * Manufacturer index
 * 
 * @param {*} param0
 */
const ManufaturerManager = ({ getDeviceManufacturer, deviceManufacturers, deleteManufacturer }) => {
  // Local state
  const [modalShow, setModalShow] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [manufacturer, setManufacturer] = useState('');
  const [formValue, setFormValue] = useState(defaultValue);

  // Get initial values 
  useEffect(() => {
    getDeviceManufacturer();
  }, [getDeviceManufacturer]);


  // Get edit manufacturer details
  const getManufacturerDetails = value => {
    setFormValue(
      deviceManufacturers
        .filter(manufacturer => manufacturer._id === value)
        .map(manufacturer => {
          const userData = Object.assign({}, manufacturer);
          userData.deviceTypes = userData.deviceTypes.map(element => ({ value: element._id, label: element.name }))
          return userData;
        })[0]
    );
    modalShow === true ? setModalShow(false) : setModalShow(true);
  };

  // Create modal  
  const ToggleModal = () => {
    setFormValue(defaultValue);
    modalShow === true ? setModalShow(false) : setModalShow(true);
  };

  // Datatable configurations
  const datatable = {
    // INdividual columns customization
    columns: [
      {
        label: "No.",
        name: "",
        options: {
          sort: false,
          customBodyRender: (value, tableMeta) => {
            return tableMeta.rowIndex + 1;
          }
        }
      },
      {
        label: "Unique number",
        name: "uniqueNumber",
        sortable: true
      },
      {
        label: "Name",
        name: "name",
        sortable: true
      },
      {
        label: "Display name",
        name: "displayName",
        sortable: true
      },
      {
        label: "Contact person",
        name: "contactPersonName",
        sortable: true
      },
      {
        label: "Business contact",
        name: "businessContactNumber",
        sortable: true
      },

      {
        label: "Business email",
        name: "businessEmail",
        sortable: true
      },
      {
        label: "Support contact",
        name: "supportContactNumber",
        sortable: true
      },
      {
        label: "Support email",
        name: "supportEmail",
        sortable: true
      },
      {
        label: "Options",
        name: "_id",
        options: {
          filter: false,
          sort: false,
          customBodyRender: value => {
            return (
              <div style={{ width: "51px" }}>
                <IconButton size="small" edge="end" color="primary" onClick={() => getManufacturerDetails(value)} >
                  <Edit />
                </IconButton>
                <IconButton size="small" color="secondary" onClick={(e) => {
                  setManufacturer(value);
                  setShowDialog(true);
                }} >
                  <Delete />
                </IconButton>
              </div>
            );
          }
        }
      }
    ],
    // Set data
    data: deviceManufacturers && deviceManufacturers.length ? deviceManufacturers : [],
    
    // Set options
    options: {
      selectableRows: "none",
      download: false,
      print: false,
      viewColumns: false,
      filter: false,
      customToolbar: () => {
        return (
          <>
            <Button
              onClick={ToggleModal}
              size="small"
              variant="outlined"
              color="primary"
              startIcon={<Add />}>Add new</Button>
          </>
        );
      }
    }
  };

  // Return main view
  return (
    <div className="m-grid__item m-grid__item--fluid m-wrapper">
      <div className="m-content">
        <div className="m-portlet m-portlet--mobile">
          <MUIDataTable
            title={"Devices Manufacturer"}
            data={datatable.data}
            columns={datatable.columns}
            options={datatable.options} />
          <Dialog
            open={showDialog}
            onClose={() => setShowDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Delete Manufacturer</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this manufacturer?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => setShowDialog(false)}>Cancel </Button>
              <Button
                color="primary"
                onClick={() => {
                  deleteManufacturer(manufacturer);
                  setShowDialog(false);
                }} >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <CreateEditModal
          show={modalShow}
          onHide={ToggleModal}
          defaultValue={formValue}
          validations={Validations} />
      </div>
    </div>
  );

}

const mapStateToProps = state => ({
  deviceManufacturers: state.deviceManufacturers
});


export default connect(mapStateToProps,
  dispatch => ({
    dispatch,
    getDeviceManufacturer: () => dispatch(getDeviceManufacturer()),
    deleteManufacturer: (Id) => dispatch(deleteManufacturer(Id))
  })
)(ManufaturerManager);
