import globalHelper from "../helper/globalHelper";
import * as userService from "../services/AppUserService";
export const USER_LOADED = "USER_LOADED";
export const USER_EDIT = "USER_EDIT";

export const getUserAction = () => {
  return (dispatch) => {
    userService.getUsers().then((users) => {
      dispatch({
        type: USER_LOADED,
        users,
      });
    });
  };
};

export function updateUserAction(data) {
  return (dispatch) => {
    return userService.updateUser(data).then((userData) => {
      dispatch({
        type: USER_EDIT,
        userData,
      });
      globalHelper.message("Device limit updated successfully", "success");
      return userData;
    });
  };
}
