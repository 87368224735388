import { CLIENT_CREATED, CLIENT_LOADED, SEND_OTP, VERIFY_OTP } from '../actions/tokenActions'

export function authClientReducer(state = null, action) {
  switch (action.type) {
    case CLIENT_CREATED:
      return [
        ...state,
        action.client
      ]

    case CLIENT_LOADED:
      return action.clients

    default:
      return state
  }
}

export function authSendOtpReducer(state = [], action) {
  switch (action.type) {

    case SEND_OTP:
      return [
        ...state,
        action.authUser
      ]

    default:
      return state
  }
}


export function authVerifyOtpReducer(state = [], action) {
  switch (action.type) {

    case VERIFY_OTP:
      return [
        ...state,
        action.otpData
      ]

    default:
      return state
  }
}