import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { getPartner, deletePartner } from "../../actions/partnerActions";
import { Dialog, DialogTitle, Button, DialogActions, DialogContent, DialogContentText, IconButton } from "@material-ui/core";
import CreateEditModal from "./addEditModal";
import { defaultValue, Validations } from "./Validations";
import { Add, EditOutlined as Edit, DeleteOutlineOutlined as Delete } from "@material-ui/icons";
import { authType } from "../../constants/common";

/**
 * Manufacturer index
 *
 * @param {*} param0
 */
const PartnerManager = ({ getPartner, partners, deletePartner }) => {
  // Local state
  const [modalShow, setModalShow] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [partner, setPartner] = useState("");
  const [formValue, setFormValue] = useState(defaultValue);
  const [showDefaultPartnerCheckbox, setShowDefaultPartnerCheckbox] =
    useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [flagShow, setFlagShow] = useState(false);
  // const [showDefaultPartnerCheckedShow, setShowDefaultPartnerCheckedShow] =
  //   useState(false);

  // Get initial values
  useEffect(() => {
    getPartner();
  }, [getPartner]);

  // Get edit partner details
  const getPartnerDetails = (value) => {
    handleShowDefaultPartnerCheckbox();
    // partners.filter((partnerDefault) => {
    //   if (
    //     partnerDefault.default_partner === true &&
    //     partnerDefault._id === value
    //   ) {
    //     setShowDefaultPartnerCheckedShow(true);
    //   }
    // });
    setFormValue(
      partners
        .filter((partner) => partner._id === value)
        .map((partner) => {
          const userData = Object.assign({}, partner);
          // userData.logo = userData.logo.map(element => ({value: element._id, image: element.logo}))
          // userData.background_image = userData.background_image.map(element => ({value: element._id, image: element.background_image}))
          return userData;
        })[0]
    );
    modalShow === true ? setModalShow(false) : setModalShow(true);
  };

  const handleReloadList = async () => {
    setIsLoading(false);
    getPartner().then(() => {
      setIsLoading(true);
      handleShowDefaultPartnerCheckbox();
    });
  };

  const handleShowDefaultPartnerCheckbox = () => {
    partners && partners.filter((partnerDefault) => {
      if (partnerDefault.default_partner === true) {
        setShowDefaultPartnerCheckbox(false);
      }
    });
  };

  // Create modal
  const ToggleModal = () => {
    handleShowDefaultPartnerCheckbox();
    setFormValue(defaultValue);
    modalShow === true ? setModalShow(false) : setModalShow(true);
    setFlagShow(false);
  };

  const handleModalClose = () => {
    modalShow === true ? setModalShow(false) : setModalShow(true);
    setFlagShow(true);
  }
  // Datatable configurations
  const datatable = {
    // INdividual columns customization
    columns: [
      {
        label: "No.",
        name: "",
        options: {
          sort: false,
          customBodyRender: (value, tableMeta) => {
            return tableMeta.rowIndex + 1;
          },
        },
      },
      {
        label: "Name",
        name: "name",
        sortable: true,
      },
      {
        label: "Auth Type",
        name: "auth_type",
        sortable: true,
        options: {
          // filter: false,
          // sort: false,
          customBodyRender: (value) => {
            let authTypeLabel;
            value &&
              authType.filter((items) => {
                if (items.value == value) {
                  authTypeLabel = items.label;
                }
              });
            return authTypeLabel;
          },
        },
      },
      {
        label: "Auth URL",
        name: "auth_url",
        sortable: true,
      },
      {
        label: "Login URL",
        name: "login_url",
        sortable: true,
      },
      {
        label: "Options",
        name: "_id",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value) => {
            return (
              <div style={{ width: "51px" }}>
                <IconButton
                  size="small"
                  edge="end"
                  color="primary"
                  onClick={() => getPartnerDetails(value)}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={(e) => {
                    setPartner(value);
                    setShowDialog(true);
                  }}
                >
                  <Delete />
                </IconButton>
              </div>
            );
          },
        },
      },
    ],
    // Set data
    data: partners && partners.length ? partners : [],
    // Set options
    options: {
      selectableRows: "none",
      download: false,
      print: false,
      viewColumns: false,
      filter: false,
      customToolbar: () => {
        return (
          <>
            <Button
              onClick={ToggleModal}
              size="small"
              variant="outlined"
              color="primary"
              startIcon={<Add />}
            >
              Add new
            </Button>
          </>
        );
      },
    },
  };

  // Return main view
  return (
    <div className="m-grid__item m-grid__item--fluid m-wrapper">
      <div className="m-content">
        <div className="m-portlet m-portlet--mobile">
          {isLoading && (
            <MUIDataTable
              title={"Partner List"}
              data={datatable.data}
              columns={datatable.columns}
              options={datatable.options}
            />
          )}
          <Dialog
            open={showDialog}
            onClose={() => setShowDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Delete Partner</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this partner?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => setShowDialog(false)}>
                Cancel{" "}
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  deletePartner(partner);
                  setShowDialog(false);
                }}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <CreateEditModal
          show={modalShow}
          onHide={ToggleModal}
          reLoad={handleReloadList}
          defaultValue={formValue}
          validations={Validations}
          defaultPartner={showDefaultPartnerCheckbox}
          handleModalClose={handleModalClose}
          flagShow={flagShow}
          // defaultShowPartnerId={showDefaultPartnerCheckedShow}
        />
      </div>
      {/* {console.log("showDefaultPartnerCheckbox", showDefaultPartnerCheckbox)}
      {console.log("showDefaultPartnerId", showDefaultPartnerId)} */}
    </div>
  );
};

const mapStateToProps = state => ({
  partners: state.partners
});


export default connect(mapStateToProps,
  dispatch => ({
    dispatch,
    getPartner: () => dispatch(getPartner()),
    deletePartner: (Id) => dispatch(deletePartner(Id))
  })
)(PartnerManager);
 