import * as deviceWhitelistService from '../services/DeviceWhitelistService'
export const WHITELIST_DEVICE_LOADED = 'WHITELIST_DEVICE_LOADED'
export const WHITELIST_DEVICES_ADDED = 'WHITELIST_DEVICES_ADDED'
export const DEVICE_BLOCKED = 'DEVICE_BLOCKED'


export const getDeviceAction = () => {
  return (dispatch) => {
    deviceWhitelistService.getWhitelistDevice()
      .then((whitelistDevices) => {
        dispatch({
          type: WHITELIST_DEVICE_LOADED,
          whitelistDevices
        })
      })
  }
}

export function addDevicesWhitelistAction(credentials) {
  return (dispatch) => {
    return deviceWhitelistService.addWhitelistDevices(credentials)
      .then((result) => {
        dispatch(createWhitelistAction(result))
        return result
      })
  }
}

export function createWhitelistAction(addedWhitelistDevices) {
  return {
    type: WHITELIST_DEVICES_ADDED,
    addedWhitelistDevices
  }
}

export const blockDeviceAction = (credentials) => {
  return (dispatch) => {
    return deviceWhitelistService.blockDevice(credentials)
      .then((blockedDevice) => {
        dispatch({
          type: DEVICE_BLOCKED,
          blockedDevice
        })
      })
  }
}
