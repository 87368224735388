import { WHITELIST_DEVICE_LOADED, WHITELIST_DEVICES_ADDED, DEVICE_BLOCKED } from '../actions/deviceWhitelistActions'

export function getWhitelistDeviceReducer(state = [], action) {
  switch (action.type) {

    case WHITELIST_DEVICE_LOADED:
      return action.whitelistDevices

    default:
      return state
  }
}

export function createWhitelistReducer(state = [], action) {

  switch (action.type) {
    case WHITELIST_DEVICES_ADDED:
      return [
        ...state,
        action.addedWhitelistDevices
      ]

    default:
      return state
  }
}

export function blockDeviceReducer(state = [], action) {

  switch (action.type) {
    case DEVICE_BLOCKED:
      return [
        ...state,
        action.blockedDevice
      ]

    default:
      return state
  }
}