import { ADMIN_TYPE_LOADED, COMMAND_LOADED, ADMIN_DEVICE_LOADED } from '../actions/commandActions'

export function deviceAdminTypesReducer(state = null, action) {
  switch (action.type) {

    case ADMIN_TYPE_LOADED:
      return action.deviceAdminType

    default:
      return state
  }
}

export function getCommandsReducer(state = [], action) {
  switch (action.type) {

    case COMMAND_LOADED:
      return action.commands

    default:
      return state
  }
}

export function getDevicesReducer(state = [], action) {
  switch (action.type) {

    case ADMIN_DEVICE_LOADED:
      return action.deviceslist

    default:
      return state
  }
}

/* export function createTypeReducer(state = [], action) {
  switch (action.type) {
    case CREATE_DEVICE_TYPE:
      return [
        ...state,
        action.deviceType
      ]

    default:
      return state
  }
} */