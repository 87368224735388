import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import {
  createTokenAction,
  getClientAction,
  getPartnerAction,
} from "../../../actions/tokenActions";

class TokenAdd extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      data: {
        name: "",
        display_name: "",
        redirect: "",
        notes: "",
        scope: "",
        partnerId: "",
      },
      partnerData: null,
      errors: {},
    };

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }
  handleClick() {
    this.props.getPartnerAction((data) => this.setState({ data }));
  }
  componentDidMount() {
    if (this.props.match.params.id) {
      const data = {
        id: this.props.match.params.id,
      };

      this.props
        .getClient(data)
        .then((client) => {
          if (client.error) {
            this.setState({
              data: this.state.data,
              errors: client.error,
            });
          } else {
            this.setState({
              data: {
                _id: client._id,
                name: client.name,
                display_name: client.display_name,
                redirect: client.redirect.toString(),
                notes: client.notes,
                scope: client.scope,
                partnerId: client.partnerId,
              },
            });
          }
        })
        .catch((err) => console.log("Failure", err));
    }

    const data = {
      partnerData: null,
    };

    this.props.getPartner(data).then((partner) => {
      // console.log("partnerdata",partner)
      if (partner.error) {
        this.setState({
          partnerData: this.state.data,
          errors: partner.error,
        });
      } else {
        this.setState({
          partnerData: partner,
        });
      }
    });
  }

  getInitialState = () => {
    return {
      data: {
        name: "",
        display_name: "",
        redirect: "",
        notes: "",
        scope: "",
        partnerId: "",
      },
      errors: {},
    };
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { data } = this.state;
    let checkDefaultPartner = [];
    if (this.validator.allValid()) {
      this.state.partnerData.map((defaultPartnerCheck) => {
        if (defaultPartnerCheck.default_partner === true && data.partnerId === "") {
          // console.log("defaultPartnerCheck._id",defaultPartnerCheck._id)
          /* data.partnerId = this.state.partnerData[1]._id; */
          data.partnerId = defaultPartnerCheck._id;
        }
        if (defaultPartnerCheck.default_partner === false && data.partnerId === "") {
          checkDefaultPartner.push(defaultPartnerCheck)
        }
      })
      if (checkDefaultPartner.length == this.state.partnerData.length) {
        toast.error("Please select partner or add one default partner");
      }
      else{
      this.props
        .createToken(data)
        .then(() => {
          this.props.history.push("/oauth-token");
          this.validator.hideMessages();
        })
        .catch((err) => console.log("Failure", err));
      this.setState(this.getInitialState());
    }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleCheck = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.checked,
      },
    });
  };

  render() {
    const { name, display_name, redirect, notes, scope, partnerId } = this.state.data;

    return (
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content">
          <div className="m-portlet m-portlet--mobile">
            <div className="m-portlet__head">
              <div className="m-portlet__head-caption">
                <div className="m-portlet__head-title">
                  <h3 className="m-portlet__head-text">
                    {this.state.data._id ? "Update Client" : "Create Client"}
                  </h3>
                </div>
              </div>
            </div>
            <form
              className="m-form m-form--fit m-form--label-align-right m-form--group-seperator"
              onSubmit={this.handleSubmit}
            >
              <div className="m-portlet__body">
                <div className="m-form__section m-form__section--first">
                  <div className="form-group m-form__group row">
                    <label className="col-lg-2 col-form-label">Partner:</label>
                    <div className="col-lg-6">
                      <select
                        className="form-control m-input"
                        name="partnerId"
                        value={
                          partnerId !== "" && partnerId !== undefined
                            ? partnerId
                            : ""
                        }
                        onChange={this.handleChange}
                        autoComplete="off"
                      >
                        <option value="">Select Partner</option>
                        {this.state.partnerData !== null &&
                          this.state.partnerData !== undefined
                          ? this.state.partnerData.map(function (partner, i) {
                            return (
                              <option value={partner._id} key={i}>
                                {partner.name}
                              </option>
                            );
                          })
                          : ""}
                      </select>
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <label className="col-lg-2 col-form-label">
                      Name:
                    </label>
                    <div className="col-lg-6">
                      <input
                        type="text"
                        name="name"
                        className="form-control m-input"
                        onChange={this.handleChange}
                        autoComplete="off"
                        value={name}
                      />
                      {this.validator.message("name", name, "required")}
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <label className="col-lg-2 col-form-label">
                      Display Name:
                    </label>
                    <div className="col-lg-6">
                      <input
                        type="text"
                        name="display_name"
                        className="form-control m-input"
                        onChange={this.handleChange}
                        autoComplete="off"
                        value={display_name}
                      />
                      {/* {this.validator.message("display_name", display_name, "required")} */}
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <label className="col-lg-2 col-form-label">
                      Redirect URL:
                    </label>
                    <div className="col-lg-6">
                      <input
                        type="text"
                        name="redirect"
                        className="form-control m-input"
                        onChange={this.handleChange}
                        value={redirect}
                      />
                      {this.validator.message("redirect", redirect, "required")}
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <label className="col-lg-2 col-form-label">Notes:</label>
                    <div className="col-lg-6">
                      <textarea
                        type="text"
                        name="notes"
                        className="form-control m-input"
                        onChange={this.handleChange}
                        value={notes}
                      />
                      {/* {this.validator.message("notes", notes, "required")} */}
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <label className="col-lg-2 col-form-label">Scope:</label>
                    <div className="col-lg-6">
                      <div className="m-checkbox-inline">
                        <label className="m-checkbox">
                          <input
                            type="checkbox"
                            name="scope"
                            checked={scope}
                            onChange={this.handleCheck}
                          />
                          <span></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="m-portlet__foot m-portlet__foot--fit">
                <div className="m-form__actions m-form__actions">
                  <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-6">
                      <button type="submit" className="btn btn-primary">
                        {this.state.data._id
                          ? "Update Client"
                          : "Create Client"}
                      </button>
                      &nbsp;
                      <Link to="/oauth-token" className="btn btn-secondary">
                        Cancel
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {console.log("this.state", this.state)}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    client: state.client,
  }),
  (dispatch) => ({
    dispatch,
    createToken: (credentials) => dispatch(createTokenAction(credentials)),
    getClient: (credentials) => dispatch(getClientAction(credentials)),

    getPartner: (credentials) => dispatch(getPartnerAction(credentials)),
  })
)(TokenAdd);
