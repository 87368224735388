/* eslint-disable react/prop-types */
import { Formik } from "formik";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import LoadingSubmit from "../../components/LoadingSubmit";
import {
  savePartner,
  editPartner,
} from "../../actions/partnerActions";
import Select from "react-select";
import { authDic } from "../../constants/common";
import { toast } from "react-toastify";

function CreateUserModal({
  validations,
  savePartner,
  editPartner,
  defaultValue,
  onHide,
  show,
  defaultPartner,
  reLoad,
  handleModalClose,
  flagShow,
  // defaultShowPartnerId,
  setSubmitting,
  resetForm,
  setErrors,
}) {
  const [logo, setLogo] = useState([]);
  const [background_image, setBackgroundImage] = useState([]);
  const [footer_image, setFooterImage] = useState([]);
  var [auth_type, setAuthType] = useState([]);
  const [showPartnerCheckbox, setShowPartnerCheckbox] = useState(false);
  const [showPrivew, setShowPrivew] = useState(false);
  const [showPrivewTwo, setShowPrivewTwo] = useState(false);
  const [showPrivewThree, setShowPrivewThree] = useState(false);
  const [tempFile, setTempFile] = useState([]);
  const [tempFileTwo, setTempFileTwo] = useState([]);
  const [tempFileThree, setTempFileThree] = useState([]);

  auth_type = [
    { value: 1, label: "Smart India Default" },
    {
      value: 2,
      label: "Login and password to fetch access_token with expiry",
    },
    {
      value: 3,
      label:
        "Login and password to fetch access_token & refresh_token with expiry",
    },
    { value: 4, label: "Pre-shared Secret token" },
    {
      value: 5,
      label: "Pre-shared secret token to fetch access_token with expiry",
    },
    {
      value: 6,
      label:
        "Pre-shared secret token to fetch access_token & refresh_token with expiry",
    },
  ];

  useEffect(() => {
    handleShowCheckbox();
    if( flagShow === true) {
      setTempFile([]);
      setTempFileTwo([]);
      setTempFileThree([]);
    }
  },[flagShow]);

  /**
   * Return create modal
   */
  const handleSubmit = async (evt, { setSubmitting, setErrors, resetForm }) => {
    setSubmitting(true);
    let bodyFormData = new FormData();
    const errors = [];
    if (defaultValue._id) {
      //   console.log("Auth type: ", evt.auth_type);
      bodyFormData.set("name", evt.name);
      // bodyFormData.set("auth_type", evt.auth_type[0].value)
      if (typeof evt.auth_type.value == "number") {
        bodyFormData.set("auth_type", evt.auth_type.value);
      } else {
        bodyFormData.set("auth_type", evt.auth_type);
      }
      bodyFormData.set("auth_message", evt.auth_message);
      bodyFormData.set("auth_url", evt.auth_url);
      bodyFormData.set("login_url", evt.login_url);
      bodyFormData.set("pre_shared_token", evt.pre_shared_token);
      bodyFormData.set("default_partner", evt.default_partner);
       //getting header data
       bodyFormData.set("header_key_one", evt.header_key_one);
       bodyFormData.set("header_value_one", evt.header_value_one);
       bodyFormData.set("header_key_two", evt.header_key_two);
       bodyFormData.set("header_value_two", evt.header_value_two);
       bodyFormData.set("header_key_three", evt.header_key_three);
       bodyFormData.set("header_value_three", evt.header_value_three);
       // getting body data
       bodyFormData.set("body_key_one", evt.body_key_one);
       bodyFormData.set("body_value_one", evt.body_value_one);
       bodyFormData.set("body_key_two", evt.body_key_two);
       bodyFormData.set("body_value_two", evt.body_value_two);
       bodyFormData.set("body_key_three", evt.body_key_three);
       bodyFormData.set("body_value_three", evt.body_value_three);
      if(evt.placeholder_Id === "" ) {
        bodyFormData.set("placeholder_Id","ID");
      } else {
        bodyFormData.set("placeholder_Id",evt.placeholder_Id);
      }
      if (logo.length != 0) {
        bodyFormData.append("logo", logo, "logo.png");
      }
      if (background_image.length != 0) {
        bodyFormData.append(
          "background_image",
          background_image,
          "background_image.png"
        );
      }

      if (footer_image.length != 0) {
        bodyFormData.append("footer_image", footer_image, "footer_image.png");
      }

      var x = bodyFormData.values();
      editPartner(bodyFormData, defaultValue._id).then((result) => {
        setSubmitting(false);
        resetForm(defaultValue);
        onHide();
        reLoad();
      });
    } else {
      bodyFormData.set("name", evt.name);
      // bodyFormData.set("auth_type", evt.auth_type[0].value);
      // if (values.auth_type !== "") {
      //   bodyFormData.set("auth_type", 1);
      // } else {
      if (evt.auth_type === "") {
        bodyFormData.set("auth_type", 1);
      } else {
        bodyFormData.set("auth_type", evt.auth_type.value);
      }
      // }
      bodyFormData.set("auth_message", evt.auth_message);
      bodyFormData.set("auth_url", evt.auth_url);
      bodyFormData.set("login_url", evt.login_url);
      bodyFormData.set("pre_shared_token", evt.pre_shared_token);
      bodyFormData.set("default_partner", evt.default_partner);
      //getting header data
      bodyFormData.set("header_key_one", evt.header_key_one);
      bodyFormData.set("header_value_one", evt.header_value_one);
      bodyFormData.set("header_key_two", evt.header_key_two);
      bodyFormData.set("header_value_two", evt.header_value_two);
      bodyFormData.set("header_key_three", evt.header_key_three);
      bodyFormData.set("header_value_three", evt.header_value_three);
      // getting body data
      bodyFormData.set("body_key_one", evt.body_key_one);
      bodyFormData.set("body_value_one", evt.body_value_one);
      bodyFormData.set("body_key_two", evt.body_key_two);
      bodyFormData.set("body_value_two", evt.body_value_two);
      bodyFormData.set("body_key_three", evt.body_key_three);
      bodyFormData.set("body_value_three", evt.body_value_three);
      if(evt.placeholder_Id === "" ) {
        bodyFormData.set("placeholder_Id","ID");
      } else {
        bodyFormData.set("placeholder_Id",evt.placeholder_Id);
      }
      if (logo.length != 0) {
        bodyFormData.append("logo", logo, "logo.png");
      }
      if (background_image.length != 0) {
        bodyFormData.append(
          "background_image",
          background_image,
          "background_image.png"
        );
      }

      if (footer_image.length != 0) {
        bodyFormData.append("footer_image", footer_image, "footer_image.png");
      }
      if (
        logo.length !== 0 &&
        background_image.length !== 0 &&
        footer_image.length !== 0
      ) {
        savePartner(bodyFormData).then((result) => {
          setSubmitting(false);
          resetForm(defaultValue);
          onHide();
          reLoad();
        });
      } else {
        toast.error("Please add Logo, Background and Footer Image");
      }
    }
  };

  const handleShowCheckbox = async () => {
    if (defaultValue.default_partner === true) {
      setShowPartnerCheckbox(true);
    } else if (defaultValue.default_partner === false) {
      setShowPartnerCheckbox(false);
    } else if (!defaultValue.default_partner) {
      setShowPartnerCheckbox(false);
    }
  };

  const setLogoFile = (evt) => {
    setLogo(evt.target.files[0]);
    setTempFile(URL.createObjectURL(evt.target.files[0]))
    // let img = new Image(evt.target.files[0]);
    // let fr = new FileReader();
    // var objectUrl = _URL.createObjectURL(evt.target.files[0]);
    // img.onload = function () {
    // console.log("img", evt.target);
    // console.log("fr", fr);
    //   _URL.revokeObjectURL(objectUrl);
    // };
  };
  const setBGFile = (evt) => {
    setBackgroundImage(evt.target.files[0]);
    setTempFileTwo(URL.createObjectURL(evt.target.files[0]))
  };

  const setFooterFile = (evt) => {
    setFooterImage(evt.target.files[0]);
    setTempFileThree(URL.createObjectURL(evt.target.files[0]))
  };
  const handleOpen = () => {
    setShowPrivew(true);
  }
  const handleClose = () => {
    setShowPrivew(false);
  }
  
  const handleOpenTwo = () => {
    setShowPrivewTwo(true);
  }
  const handleCloseTwo = () => {
    setShowPrivewTwo(false);
  }

  const handleOpenThree = () => {
    setShowPrivewThree(true);
  }
  const handleCloseThree = () => {
    setShowPrivewThree(false);
  }
  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validations}
      onSubmit={handleSubmit}
      // onSubmit={(inputs, { setSubmitting, setErrors, resetForm } ) => {
      //     let values = Object.assign({},inputs);
      //     // let values = new FormData()
      //     // console.log("event: ", event.target.input.files[0]);
      //     setSubmitting(true);
      //     if (defaultValue._id) {
      //         // console.log("Values: ", values)
      //         editPartner(values, defaultValue._id).then((result) => {
      //             setSubmitting(false);
      //             resetForm(defaultValue)
      //             onHide();
      //         });
      //     // else {
      //     //     bodyFormData = {addPartner}
      //     //     savePartner(bodyFormData).then((result) => {
      //     //         setSubmitting(false);
      //     //         resetForm(defaultValue)
      //     //         onHide();
      //     //     });
      //         // console.log("Values: ", values);
      //         // savePartner(values).then((result) => {
      //         //     setSubmitting(false);
      //         //     resetForm(defaultValue)
      //         //     onHide();
      //         // });
      //     }
      // },
      // () => handleSubmit}
      initialValues={defaultValue}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        setFieldTouched,
        errors,
        resetForm,
        setFieldValue,
        isSubmitting,
      }) => (
        <Modal
          show={show}
          onHide={onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onShow={() => (show ? resetForm() : false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {defaultValue._id ? "Edit Partner" : "Add New Partner"}
              {/* Add New Partner */}
            </Modal.Title>
          </Modal.Header>
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Body>
              <Form.Row>
                {/* Name  */}
                <Form.Group as={Col} md="6" controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    isInvalid={touched.name && !!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {" "}
                    {errors.name}{" "}
                  </Form.Control.Feedback>
                </Form.Group>
                {/* auth_type  */}
                {
                  <Form.Group as={Col} md="6" controlId="auth_type">
                    <Form.Label>Auth Type</Form.Label>
                    {/* <Select
                        name="auth_type"
                        defaultValue={authDic[defaultValue.auth_type]}
                        onChange={(value) => {
                          setFieldValue("auth_type", value);
                          {
                            console.log("valuee", value);
                          }
                        }}
                        options={auth_type}
                        // isMulti={true}
                        value={values.authType}
                      /> */}
                    {defaultValue._id ? (
                      <Select
                        value={values.authType}
                        onChange={(value) => {
                          setFieldValue("auth_type", value);
                          // handleFields();
                        }}
                        defaultValue={authDic[defaultValue.auth_type]}
                        options={auth_type}
                        // isInvalid={touched.auth_type && !!errors.auth_type}
                      />
                    ) : (
                      <Select
                        value={values.authType}
                        onChange={(value) => {
                          setFieldValue("auth_type", value);
                        }}
                        defaultValue={[
                          { value: 1, label: "Smart India Default" },
                        ]}
                        options={auth_type}
                        // isInvalid={touched.auth_type && !!errors.auth_type}
                      />
                    )}
                    {/* <Form.Control.Feedback type="invalid">
                      {" "}
                      {errors.auth_type}{" "}
                    </Form.Control.Feedback> */}
                  </Form.Group>
                }
                {/* Logo  */}
                <Form.Group as={Col} md="4" controlId="logo">
                  <Form.Label>Logo</Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="Logo"
                    name="logo"
                    value={undefined}
                    onChange={setLogoFile}
                    isInvalid={touched.logo && !!errors.logo}
                  />
                  {/* preview modal */}
                  {((defaultValue.logo.length > 0 ) || (tempFile.length !== 0)) && <a onClick={handleOpenThree} style={{ color:"blue", cursor:"pointer" }}>Preview</a>}
                  {((defaultValue.logo.length > 0 || tempFile) || (defaultValue._id != 0)) && 
                  <Modal
                    size="lg"
                    show={showPrivewThree}
                    onHide={handleCloseThree}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title className="font-weight-bold">
                        Preview Attachment
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {(defaultValue.logo.length > 0) ? <img alt="File" src={`${process.env.REACT_APP_IMAGE_PATH}logoImage/${defaultValue.logo}`} className="w-100" /> :
                     <img alt="File" src={tempFile} className="w-100" /> }
                    </Modal.Body>
                  </Modal> }
                  <Form.Text id="passwordHelpBlock" muted>
                    image dimensions should be width: 260 px and height: 60 px.
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {" "}
                    {errors.logo}{" "}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* Background Image  */}
                <Form.Group as={Col} md="4" controlId="background_image">
                  <Form.Label>Background Image</Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="Background Image"
                    name="background_image"
                    // defaultValue={defaultValue.background_image}
                    value={undefined}
                    onChange={setBGFile}
                    isInvalid={
                      touched.background_image && !!errors.background_image
                    }
                  />
                  {((defaultValue.background_image.length > 0 ) || (tempFileTwo.length !== 0)) && <a onClick={handleOpen} style={{ color:"blue", cursor:"pointer" }}>Preview</a>}
                  {((defaultValue.background_image.length > 0 || tempFileTwo) || (defaultValue._id != 0)) && 
                  <Modal
                    size="lg"
                    show={showPrivew}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title className="font-weight-bold">
                        Preview Attachment
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {(defaultValue.background_image.length > 0) ? <img alt="File" src={`${process.env.REACT_APP_IMAGE_PATH}backgoundImage/${defaultValue.background_image}`} className="w-100" /> : 
                     <img alt="File" src={tempFileTwo} className="w-100" /> }
                    </Modal.Body>
                  </Modal> }
                  
                  <Form.Control.Feedback type="invalid">
                    {" "}
                    {errors.background_image}{" "}
                  </Form.Control.Feedback>
                </Form.Group>
                {/* Footer Image  */}
                <Form.Group as={Col} md="4" controlId="footer_image">
                  <Form.Label>Footer Image</Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="Footer Image"
                    name="footer_image"
                    // defaultValue={defaultValue.footer_image}
                    value={undefined}
                    onChange={setFooterFile}
                    isInvalid={touched.footer_image && !!errors.footer_image}
                  />
                  {/* preview modal */}
                  {((defaultValue.footer_image.length > 0 ) || (tempFileThree.length !== 0)) && <a onClick={handleOpenTwo} style={{ color:"blue", cursor:"pointer" }}>Preview</a>}
                  {((defaultValue.footer_image.length > 0 || tempFileThree) || (defaultValue._id != 0)) && 
                  <Modal
                    size="lg"
                    show={showPrivewTwo}
                    onHide={handleCloseTwo}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title className="font-weight-bold">
                        Preview Attachment
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {(defaultValue.footer_image.length > 0) ? <img alt="File" src={`${process.env.REACT_APP_IMAGE_PATH}footerImage/${defaultValue.footer_image}`} className="w-100" /> : 
                     <img alt="File" src={tempFileThree} className="w-100" /> }
                    </Modal.Body>
                  </Modal> }
                  <Form.Text id="passwordHelpBlock" muted>
                    image dimensions should be width: 260 px and height: 60 px.
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {" "}
                    {errors.footer_image}{" "}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* auth_type  */}
                {/* <Form.Group as={Col} md="6" controlId="auth_type">
                                        <Form.Label>Auth Type</Form.Label>
                                        <Form.Control type="text" placeholder="auth_type" name="auth_type"
                                            value={values.auth_type} onChange={handleChange} isInvalid={touched.auth_type && !!errors.auth_type} />
                                        <Form.Control.Feedback type="invalid"> {errors.auth_type} </Form.Control.Feedback>
                                    </Form.Group> */}
                {/* auth_message  */}
                <Form.Group as={Col} md="6" controlId="auth_message">
                  <Form.Label>Auth Message</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Auth Message"
                    name="auth_message"
                    value={values.auth_message}
                    onChange={handleChange}
                    isInvalid={touched.auth_message && !!errors.auth_message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {" "}
                    {errors.auth_message}{" "}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="placeholder_Id">
                  <Form.Label>Placeholder Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Placeholder Name"
                    name="placeholder_Id"
                    value={values.placeholder_Id}
                    onChange={handleChange}
                  />
                </Form.Group>

                {values.auth_type !== "" &&
                  values.auth_type !== 1 &&
                  values.auth_type.value !== 1 &&
                  values.auth_type !== 3 &&
                  values.auth_type.value !== 3 &&
                  values.auth_type !== 4 &&
                  values.auth_type.value !== 4 &&
                  values.auth_type !== 5 &&
                  values.auth_type.value !== 5 &&
                  values.auth_type !== 6 &&
                  values.auth_type.value !== 6 && (
                  <>
                  <Form.Group as={Col} md="6" controlId="header_key_one">
                <Form.Label>Header</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="key"
                    name="header_key_one"
                    value={values.header_key_one}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="header_value_one">
                  <Form.Control
                    type="text"
                    placeholder="value"
                    name="header_value_one"
                    value={values.header_value_one}
                    onChange={handleChange}
                    style={{"marginTop":"27px"}}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="header_key_two">
                  <Form.Control
                    type="text"
                    placeholder="key"
                    name="header_key_two"
                    value={values.header_key_two}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="header_value_two">
                  <Form.Control
                    type="text"
                    placeholder="value"
                    name="header_value_two"
                    value={values.header_value_two}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="header_key_three">
                  <Form.Control
                    type="text"
                    placeholder="key"
                    name="header_key_three"
                    value={values.header_key_three}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="header_value_three">
                  <Form.Control
                    type="text"
                    placeholder="value"
                    name="header_value_three"
                    value={values.header_value_three}
                    onChange={handleChange}
                  />
                </Form.Group>
                  </>)}
                
                {values.auth_type !== "" &&
                  values.auth_type !== 1 &&
                  values.auth_type.value !== 1 &&
                  values.auth_type !== 3 &&
                  values.auth_type.value !== 3 &&
                  values.auth_type !== 4 &&
                  values.auth_type.value !== 4 &&
                  values.auth_type !== 5 &&
                  values.auth_type.value !== 5 &&
                  values.auth_type !== 6 &&
                  values.auth_type.value !== 6 && (
                    <>
                    <Form.Group as={Col} md="6" controlId="body_key_one">
                  <Form.Label>Body</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="key"
                    name="body_key_one"
                    value={values.body_key_one }
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="body_value_one">
                  <Form.Control
                    type="text"
                    placeholder="value"
                    name="body_value_one"
                    value={values.body_value_one}
                    onChange={handleChange}
                    style={{ "marginTop": "27px" }}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="body_key_two">
                  <Form.Control
                    type="text"
                    placeholder="key"
                    name="body_key_two"
                    value={values.body_key_two}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="body_value_two">
                  <Form.Control
                    type="text"
                    placeholder="value"
                    name="body_value_two"
                    value={values.body_value_two}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="body_key_three">
                  <Form.Control
                    type="text"
                    placeholder="key"
                    name="body_key_three"
                    value={values.body_key_three}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="body_value_three">
                  <Form.Control
                    type="text"
                    placeholder="value"
                    name="body_value_three"
                    value={values.body_value_three}
                    onChange={handleChange}
                  />
                </Form.Group>
                </>
                  )}

                {/* Auth URL  */}
                {values.auth_type !== "" &&
                  values.auth_type !== 1 &&
                  values.auth_type.value !== 1 &&
                  values.auth_type !== 4 &&
                  values.auth_type.value !== 4 &&
                  values.auth_type !== 5 &&
                  values.auth_type.value !== 5 &&
                  values.auth_type !== 6 &&
                  values.auth_type.value !== 6 && (
                    <Form.Group as={Col} md="6" controlId="auth_url">
                      <Form.Label>Auth URL</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Auth URL"
                        name="auth_url"
                        value={values.auth_url}
                        onChange={handleChange}
                        isInvalid={touched.auth_url && !!errors.auth_url}
                      />
                      <Form.Control.Feedback type="invalid">
                        {" "}
                        {errors.auth_url}{" "}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}

                {/* Login URL  */}
                {values.auth_type !== "" &&
                  values.auth_type !== 1 &&
                  values.auth_type.value !== 1 && (
                    <Form.Group as={Col} md="6" controlId="login_url">
                      <Form.Label>Login URL</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Login URL"
                        name="login_url"
                        value={values.login_url}
                        onChange={handleChange}
                        isInvalid={touched.login_url && !!errors.login_url}
                      />
                      <Form.Control.Feedback type="invalid">
                        {" "}
                        {errors.login_url}{" "}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}

                {/* Pre-Shared Token  */}
                {values.auth_type !== "" &&
                  values.auth_type !== 1 &&
                  values.auth_type.value !== 1 &&
                  values.auth_type !== 2 &&
                  values.auth_type.value !== 2 &&
                  values.auth_type !== 3 &&
                  values.auth_type.value !== 3 && (
                    <Form.Group as={Col} md="6" controlId="pre_shared_token">
                      <Form.Label>Pre shared Token</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Pre Shared Token"
                        name="pre_shared_token"
                        value={values.pre_shared_token}
                        onChange={handleChange}
                        isInvalid={
                          touched.pre_shared_token && !!errors.pre_shared_token
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {" "}
                        {errors.pre_shared_token}{" "}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}

                {/* Default Partner  */}
                {defaultPartner === true || showPartnerCheckbox === true ? (
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="default_partner"
                    style={{ marginLeft: "3%" }}
                  >
                    <Form.Check
                      type="checkbox"
                      label="Default Partner"
                      //   value={values.default_partner}
                      checked={values.default_partner}
                      onChange={(e) =>
                        setFieldValue("default_partner", e.target.checked)
                      }
                    />
                  </Form.Group>
                ) : (
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="default_partner"
                    style={{ marginLeft: "3%" }}
                  >
                    <Form.Check
                      type="checkbox"
                      label="Default Partner"
                      disabled
                      //   value={values.default_partner}
                      checked={values.default_partner}
                      onChange={(e) =>
                        setFieldValue("default_partner", e.target.checked)
                      }
                    />
                  </Form.Group>
                )}
              </Form.Row>
            </Modal.Body>
            <Modal.Footer>
              <LoadingSubmit isLoading={isSubmitting} text="Save" />
              <Button variant="danger" onClick={handleModalClose}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
          {/* {console.log("state values", values.auth_type)} */}
        </Modal>
      )}
    </Formik>
  );
}

export default connect("", {
  savePartner,
  editPartner,
})(CreateUserModal);
