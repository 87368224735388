import { createStore, applyMiddleware } from "redux";
// import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension"
import promiseMiddleware from "redux-promise";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducers";

const sagaMiddleware = createSagaMiddleware();

// export const loggerMiddleware = createLogger();

export const middleware =
  process.env.NODE_ENV === "development"
    ? applyMiddleware(
      sagaMiddleware,
      promiseMiddleware,
      thunkMiddleware,
    //  loggerMiddleware
    )
    : applyMiddleware(sagaMiddleware, promiseMiddleware, thunkMiddleware);

export const store = createStore(rootReducer, composeWithDevTools(middleware));
