import React, { Component } from "react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { addDevicesWhitelistAction } from "../../../actions/deviceWhitelistActions";
import LoadingSubmit from "../../../components/LoadingSubmit";

class DeviceWhitelistAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        whitelist: ""
      },
      errors: {},
      isSubmitting: false
    };

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  getInitialState = () => {
    return {
      data: {
        whitelist: ""
      },
      errors: {}
    };
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ isSubmitting: true });
    const { whitelist } = this.state.data;
    if (this.validator.allValid()) {
      await this.props
        .createDevice({ whitelist })
        .then(() => {
          this.setState({ isSubmitting: false });
          this.props.history.push("/device-whitelist");
        })
        .catch(err => console.log("Failure", err));
    } else {
      this.setState({ isSubmitting: false });
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleChange = e => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.files[0]
      }
    });
  };

  render() {
    return (
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content">
          <div className="m-portlet m-portlet--mobile">
            <div className="m-portlet__head">
              <div className="m-portlet__head-caption">
                <div className="m-portlet__head-title">
                  <h3 className="m-portlet__head-text">
                    Add Device To Whitelist
                  </h3>
                </div>
              </div>
            </div>
            <form
              className="m-form m-form--fit m-form--label-align-right m-form--group-seperator"
              onSubmit={this.handleSubmit}
              encType="multipart/form-data"
              method="POST"
            >
              <div className="m-portlet__body">
                <div className="m-form__section m-form__section--first">
                  <div className="form-group m-form__group row">
                    <label className="col-lg-2 col-form-label">
                      Type Name:
                    </label>
                    <div className="col-lg-6">
                      <input
                        type="file"
                        name="whitelist"
                        onChange={this.handleChange}
                      />
                      <div className="form-control-feedback">
                        {this.validator.message(
                          "whitelist",
                          this.state.data.whitelist,
                          "required"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="m-portlet__foot m-portlet__foot--fit">
                <div className="m-form__actions m-form__actions">
                  <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-6">
                      <LoadingSubmit
                        isLoading={this.state.isSubmitting}
                        text="Create Type"
                      />
                      {/* <button type="submit" className="btn btn-primary">
                        Create Type
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    //addedWhitelistDevices: state.addedWhitelistDevices
  }),
  dispatch => ({
    dispatch,
    createDevice: formData => dispatch(addDevicesWhitelistAction(formData))
  })
)(DeviceWhitelistAdd);
