export const authType = [
  { value: "1", label: "Smart India Default" },
  { value: "2", label: "Login and password to fetch access_token with expiry" },
  {
    value: "3",
    label:
      "Login and password to fetch access_token & refresh_token with expiry",
  },
  { value: "4", label: "Pre-shared Secret token" },
  {
    value: "5",
    label: "Pre-shared secret token to fetch access_token with expiry",
  },
  {
    value: "6",
    label:
      "Pre-shared secret token to fetch access_token & refresh_token with expiry",
  },
];

export var authDic = {
  1: { value: "1", label: "Smart India Default" },
  2: {
    value: "2",
    label: "Login and password to fetch access_token with expiry",
  },
  3: {
    value: "3",
    label:
      "Login and password to fetch access_token & refresh_token with expiry",
  },
  4: { value: "4", label: "Pre-shared Secret token" },
  5: {
    value: "5",
    label: "Pre-shared secret token to fetch access_token with expiry",
  },
  6: {
    value: "6",
    label:
      "Pre-shared secret token to fetch access_token & refresh_token with expiry",
  },
};

export const DEVICELIMIT = 250;