import _ from "lodash";
import {globalActionTypes as globalActionType } from "../actions/globalAction";


class globalHelper {
  store = null;

  /**
   * Take store instance
   */
  setStore = store => {
    this.store = store;
  };

  /**
   * Show toast message
   * type: success, error, warning, info
   */
  message = (message = "", type = "success") => {
    if (this.store) {
      this.store.dispatch({
        type: globalActionType.SHOW_SNACKBAR,
        payload: {
          open: true,
          type,
          message
        }
      });
    }
  };
  
  /**
   * Set server side errors into formik form
   */
  setServerErrors = (result, setErrors) => {
    if (!result.status && result.data.error) {
      const err = _.mapValues(result.data.error, (element) => {
        return element.message;
      })
      setErrors(err);
    }
  }


}

export default new globalHelper();
