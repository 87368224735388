import { DEVICE_LOADED } from '../actions/deviceActions'

export function deviceReducer(state = null, action) {
  switch (action.type) {

    case DEVICE_LOADED:
      return action.devices

    default:
      return state
  }
}