import React, { Component } from "react";
import { Link } from "react-router-dom";

class SideNavLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {}
    };
  }
  render() {
    const { routes } = this.props;
    return (
      <div
        id="m_aside_left"
        className="m-grid__item	m-aside-left  m-aside-left--skin-dark "
      >
        <div
          id="m_ver_menu"
          className="m-aside-menu  m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark "
          data-menu-vertical="true"
          data-menu-scrollable="false"
          data-menu-dropdown-timeout="500"
        >
          <ul className="m-menu__nav">
            {routes.map((route, idx) => {
              let activeLink = "";
              const navArr = this.props.history.location.pathname.split("/");
              const curNavArr = route.path.split("/");
              if (navArr.includes(curNavArr[1])) {
                activeLink = "m-menu__item--open";
              }
              if (route.isSidenav) {
                return (
                  <li
                    className={`m-menu__item ${activeLink}`}
                    aria-haspopup="true"
                    key={idx}
                  >
                    <Link to={route.path} className="m-menu__link ">
                      <i className={`m-menu__link-icon ${route.icon}`}></i>
                      <span className="m-menu__link-title">
                        <span className="m-menu__link-wrap">
                          <span className="m-menu__link-text">
                            {route.name}
                          </span>
                        </span>
                      </span>
                    </Link>
                  </li>
                );
              } else {
                return "";
              }
            })}
          </ul>
        </div>
      </div>
    );
  }
}
export default SideNavLayout;
