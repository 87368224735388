import { DEVICE_TYPE_LOADED, DEVICE_DEFAULT_TYPE_LOADED, DEVICE_DEFAULT_CAPABILITIES_LOADED, CREATE_DEVICE_TYPE } from '../actions/deviceTypeActions'

export function deviceTypesReducer(state = null, action) {
  switch (action.type) {

    case DEVICE_TYPE_LOADED:
      return action.deviceTypes

    default:
      return state
  }
}

export function deviceDefaultTypesReducer(state = [], action) {
  switch (action.type) {

    case DEVICE_DEFAULT_TYPE_LOADED:
      return action.defaultDeviceType

    default:
      return state
  }
}

export function deviceDefaultCapatibilityReducer(state = [], action) {
  switch (action.type) {

    case DEVICE_DEFAULT_CAPABILITIES_LOADED:
      return action.defaultCapatibility

    default:
      return state
  }
}

export function createTypeReducer(state = [], action) {
  switch (action.type) {
    case CREATE_DEVICE_TYPE:
      return [
        ...state,
        action.deviceType
      ]

    default:
      return state
  }
}