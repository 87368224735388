import axios from "axios";
import apiurl from "../config/api";

export function getUsers() {
  return axios.get(apiurl.getUsers).then((result) => {
    return result.data.data;
  });
}

export function updateUser(data) {
  return axios
    .post(apiurl.updateUser, data)
    .then((result) => {
      return result.data.data;
    })
    .catch((error) => {
      return error.response;
    });
}
