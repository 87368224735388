import { USER_LOADED } from '../actions/userActions'

export function userReducer(state = null, action) {
  switch (action.type) {

    case USER_LOADED:
      return action.users

    default:
      return state
  }
}