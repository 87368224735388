import axios from 'axios';
import api from '../config/api'
import globalHelper from "../helper/globalHelper";

export const ActionTypes = {
    DEVICE_MANUFACTURER_LOADED: 'DEVICE_MANUFACTURER_LOADED',
    SAVE_DEVICE_MANUFACTURER: 'SAVE_DEVICE_MANUFACTURER',
    DEVICE_MANUFACTURER_DELETE: 'DEVICE_MANUFACTURER_DELETE',
    UPDATE_MANUFACTURER: 'UPDATE_MANUFACTURER',
}

/**
 * Get all device manufacturer
 */
export const getDeviceManufacturer = () => async dispatch => {
    axios.get(api.deviceManufacturer)
        .then(result => {
            dispatch({
                type: ActionTypes.DEVICE_MANUFACTURER_LOADED,
                deviceManufacturers: result.data.data
            })
        })
}

/**
 * Delete Manufacturer
 */
export const deleteManufacturer = (Id) => async dispatch => {
    axios.delete(`${api.deviceManufacturer}/${Id}`)
        .then(result => {
            dispatch({
                type: ActionTypes.DEVICE_MANUFACTURER_DELETE,
                payload: { Id }
            })
            globalHelper.message("Manufacturer deleted successfully", "success");
        })
}

/**
 * Create manufacturer
 */
export const saveManufacturer = (values) => async dispatch => {
    const retResponse = { status: false, data: {} };
    try {
        const { data } = await axios.post(api.deviceManufacturer, values);
        if (data.success) {
            dispatch({
                type: ActionTypes.SAVE_DEVICE_MANUFACTURER,
                payload: data.data
            });
            globalHelper.message("New Manufacturer created successfully", "success");
        }
    } catch (e) {
        if(e.response && e.response.data){
            retResponse.data = (e.response && e.response.data) ? e.response.data : retResponse.data;
            globalHelper.message(e.response.data.message, "error");
        }
    }
    return retResponse;
}


/**
 * Edit manufacturer
 */
export const editManufacturer = (values,Id) => async dispatch => {    
    const retResponse = { status: false, data: {} };
    try {
        const { data } = await axios.put(`${api.deviceManufacturer}/${Id}`, values);
        if (data.success) {    
            dispatch({
                type: ActionTypes.UPDATE_MANUFACTURER,
                payload: data.data
            });
            globalHelper.message("Manufacturer updated successfully", "success");
        }
    } catch (e) {
        if(e.response && e.response.data){
            retResponse.data = (e.response && e.response.data) ? e.response.data : retResponse.data;
            globalHelper.message(e.response.data.message, "error");
        }
    }
    return retResponse;
}





