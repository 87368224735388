import {
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  FORGOT_PASSWORD
} from '../actions/authActions'

export function authReducer(state = [], action) {
  switch (action.type) {
    case USER_LOGGED_IN:
      return action.user

    case USER_LOGGED_OUT:
      return true;

    case FORGOT_PASSWORD:
      return action.forgotPass
 
    default:
      return state
  }
}